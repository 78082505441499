/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Avatar, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfileImage } from '../../services/profileApi';
import ProfileDetails from './ProfileDetails';
import LocationOnIcon from '../../assets/icons/svg/location.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/svg/gallery.svg';
import { uploadProfileImage } from '../../services/UploadFile';
import { pushNotification } from '../../redux/notification/notificationActions';
import CONSTANTS from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: '#F2F2F2',
    overflowX: 'hidden',
    paddingBottom: '10rem',
  },
  profileBackgroundContainer: {
    backgroundColor: '#D9D9D9',
    width: '100%',
    maxHeight: '20%',
    minHeight: 100,
    position: 'relative',
  },
  avatarContainer: {
    position: 'absolute',
    bottom: -35,
    left: '2.8%',
    width: 120,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, opacity 0.3s ease', 
    [theme.breakpoints.up('xs')]: {
      left: '20%',
      width: 80,
      height: 80,
    },
    [theme.breakpoints.up('sm')]: {
      left: '6%',
      width: 90,
      height: 90,
    },
    [theme.breakpoints.up('md')]: {
      left: '3.5%',
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up('lg')]: {
      left: '3.5%',
      width: 110,
      height: 110,
    },
    [theme.breakpoints.up('xl')]: {
      left: '2%',
      width: 120,
      height: 120,
    },
  },
  avatar: {
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover',
  },
  uploadInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  userInfoContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(10),
    paddingTop: theme.spacing(6.8),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4.5),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  userInfoLocation: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(-0.5),
  },
  tabsContainer: {
    paddingLeft: theme.spacing(4),
    height: '60%',
    width: '98%',
  },

  profileDetailsContainer: {
    paddingTop: theme.spacing(2),
  },
  tabs: {
    backgroundColor: '#FFFFFF',
    paddingLeft: '2rem',
    margin: '0 -8rem 0 -2rem',
    borderBottom: '1px solid #0C87F0',
    [theme.breakpoints.up('lg')]: {
      margin: '0 -12rem 0 -2rem',
    },
    [theme.breakpoints.up('xl')]: {
      margin: '0 -16rem 0 -2rem',
    },
  },
  userName: {
    lineHeight: '1.7rem !important',
    textTransform: 'capitalize',
  },
  customIndicator: {
    height: 3,
    backgroundColor: '#0C87F0 !important',
  },
}));

const getTabsData = (initialData) => [
  {
    label: 'About',
    title: 'About',
    description: 'Client account information',
    data: initialData?.user_details,
  },
  {
    label: 'Company',
    title: 'Company',
    description: 'Client company information',
    data: initialData?.client_details,
  },
 
  {
    label: 'Domains',
    title: 'Domains',
    description: 'Client domain information',
    data: initialData?.domains_details,
  },
 
];

interface UserProfilePageProps {
  id: string;
  activeTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  userProfileData: any; 
}

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#F9F9F9', 
  '& .MuiSvgIcon-root': {
    fontSize: '2rem', 
    color: '#D9D9D9', 
  },
}));

export const CustomTab = styled(Tab)(({ theme, selected }: any) => ({
  fontFamily: 'Aeonik, sans-serif',
  fontSize: '1rem',
  fontWeight: selected ? 'bold' : 'normal',
  color: selected ? '#0C87F0 !important' : '#696969 !important',
  '@media (min-width:2560px)': {
    fontSize: '1.8rem',
  },
  '@media (min-width:3840px)': {
    fontSize: '2rem',
  },
}));

const UserProfilePage: React.FC<UserProfilePageProps> = ({
  id,
  activeTab,
  handleTabChange,
  userProfileData,
}) => {
  const classes = useStyles();
  const tabsData = getTabsData(userProfileData);

  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState('');
  const { userPreferences } = useSelector((state: any) => state?.profile);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event?.target?.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const response = await uploadProfileImage(file, file?.name);

        if (response?.success) {
          dispatch(
            pushNotification({
              isOpen: true,
              message: response?.message,
              type: CONSTANTS.SUCCESS,
            })
          );
          getUserProfileImage(dispatch);
        } else {
          dispatch(
            pushNotification({
              isOpen: true,
              message: response?.message,
              type: CONSTANTS.ERROR,
            })
          );
        }
      };

      setAvatar(reader?.result as string);

      reader?.readAsDataURL(file);
    }
  };

  return (
    <Box id={id} className={classes.container}>
      <Box
        id={`${id}-profileBackgroundContainer`}
        className={classes.profileBackgroundContainer}
      >
        <Box id={`${id}-avatarContainer`} className={classes.avatarContainer}>
          <StyledAvatar
            id={`${id}-styled-avatar`}
            alt="User Avatar"
            src={
              userPreferences?.profilePicture
                ? `data:image/png;base64,${userPreferences?.profilePicture}`
                : avatar
            }
            className={classes.avatar}
          >
            {!avatar && !userPreferences?.profilePicture && <ImageIcon />}
          </StyledAvatar>
          <input
            type="file"
            accept="image/*"
            data-testid="profile-image-upload"
            title=""
            onChange={handleImageUpload}
            className={classes.uploadInput}
          />
        </Box>
      </Box>
      <Box id={`${id}-userInfoContainer`} className={classes.userInfoContainer}>
        <Typography
          className={classes.userName}
          id={`${id}-userName`}
          fontWeight={540}
          variant="h2"
        >
          {userProfileData?.user_details?.user_name}
        </Typography>
        <Typography
          id={`${id}-companyName`}
          textTransform={'capitalize'}
          lineHeight={2}
          variant="h3"
        >
          {userProfileData?.client_details?.client_name}
        </Typography>
        <Box id={`${id}-userInfoLocation`} className={classes.userInfoLocation}>
          <img id={`${id}-locationIcon`} src={LocationOnIcon} alt="location" />
          <Typography
            id={`${id}-locationText`}
            ml={1}
            lineHeight={2}
            variant="h4"
          >
            {userProfileData?.client_details?.address}
          </Typography>
        </Box>
      </Box>
      <Box id={`${id}-tabsContainer`} className={classes.tabsContainer}>
        <Tabs
          id={`${id}-userTabs`}
          value={activeTab}
          onChange={handleTabChange}
          className={classes.tabs}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary" 
          classes={{ indicator: classes.customIndicator }} 
          aria-label="user tabs"
        >
          {tabsData?.map((tab: any, index: number) => (
            <CustomTab
              id={`${id}-tab-${index}`}
              key={`${id}-key-${index}`}
              label={tab?.label}
            />
          ))}
        </Tabs>
        <Box
          id={`${id}-profileDetailsContainer`}
          className={classes.profileDetailsContainer}
        >
          <ProfileDetails
            id={`${id}-profileDetailsComponent`}
            eachTab={tabsData[activeTab]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfilePage;
