/* eslint-disable prettier/prettier */
import { useDispatch } from 'react-redux';

import { addOrEditOrDeleteClient } from '../../../services/clientServices';
import CONSTANTS from '../../../constants/constants';
import { pushNotification } from '../../../redux/notification/notificationActions';
import ReusableForm from '../../../components/common/customForm';
import { clientFields, clientValidationSchema, formPayload } from './CreateClient';

interface EditClientProps {
  id: string;
  selectedClient: any;
  setShow: any;
  show: boolean;
  handleGetAllClients: any;
}

const EditClient: React.FC<EditClientProps> = ({
  id,
  selectedClient,
  show,
  setShow,
  handleGetAllClients,
}) => {
  const dispatch = useDispatch();
  const initialValues = {
    client_name: selectedClient?.client_name,
    business_owner: selectedClient?.business_owner,
    business_owner_email: selectedClient?.business_owner_email,
    business_owner_phone: selectedClient?.business_owner_phone,
    technical_owner: selectedClient?.technical_owner,
    technical_owner_email: selectedClient?.technical_owner_email,
    technical_owner_phone: selectedClient?.technical_owner_phone,
    address: selectedClient?.address,
    gstin: selectedClient?.gstin,
    pan: selectedClient?.pan,
  };

  const postFormValues = (values: any) => {
    const postData = formPayload(values);
    handlePostApiRequest(postData);
  };

  const handlePostApiRequest = async (data) => {
    const response = await addOrEditOrDeleteClient(
      'update',
      selectedClient.client_id,
      data
    );
    if (response?.success) {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      handleGetAllClients();
    } else {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(false);
  };

  return (
    <ReusableForm
      id={id}
      open={show}
      onClose={() => {
        setShow(false);
      }}
      initialValues={initialValues}
      validationSchema={clientValidationSchema}
      onSubmit={handleSubmit}
      fields={clientFields}
      title={'Update Client'}
      buttonLabel={CONSTANTS.UPDATE_CLIENT_BTN_TEXT}
    />
  );
};

export default EditClient;
