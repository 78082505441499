/* eslint-disable prettier/prettier */
import React from 'react';
import * as Yup from 'yup';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../redux/notification/notificationActions';
import { addOrEditOrDeleteUser } from '../../../services/userService';
import ReusableForm from '../../../components/common/customForm';
import { USER_DEFAULT_GROUPS, USER_DEFAULT_ROLES } from '../../../components/initial/defaultData';


export const userValidationSchema = Yup.object({
  userName: Yup.string()
    .required('User Name is Requried')
    .min(4, 'Minimum length is 4 Characters')
    .trim(),
  firstName: Yup.string().required('First Name is Requried'),
  lastName: Yup.string().required('Last Name is Requried'),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
    .length(10)
    .required('Mobile Number is Requried'),
  emailId: Yup.string()
    .email('Please Enter a Valid Email Id')
    .required('Email Id is Requried'),
  clientName: Yup.string(),
  department: Yup.string(),
  groups: Yup.array(),
  roles: Yup.array(),
})


const initialUserValues = {
  userName: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  emailId: '',
  clientName: '',
  department: '',
  groups: USER_DEFAULT_GROUPS,
  roles: USER_DEFAULT_ROLES,
}

const userFields = [
  { name: 'userName', label: 'User Name', type: 'text', placeholder: 'Enter user name', required: true },
  { name: 'firstName', label: 'First Name', type: 'text', placeholder: 'Enter first name', required: true },
  { name: 'lastName', label: 'Last Name', type: 'text', placeholder: 'Enter last name', required: true },
  { name: 'mobileNumber', label: 'Mobile Number', type: 'text', placeholder: 'Enter mobile number', required: true },
  { name: 'emailId', label: 'Email', type: 'email', placeholder: 'Enter email', required: true },
  { name: 'department', label: 'Job Title', type: 'text', placeholder: 'Enter job title', required: false },
];

export const userFormPayload:any = (values) => ({
  userName: values?.userName,
  firstName: values?.firstName,
  lastName: values?.lastName,
  mobileNumber: values?.mobileNumber,
  emailId: values?.emailId,
  clientName: values?.clientName,
  department: values?.department,
  groups: values?.groups,
  roles: values?.roles,
});


interface CreateUserProps  {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

const CreateUser: React.FC<CreateUserProps> = ({show,setShow}) => {

  const dispatch = useDispatch();

  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(true);

  };




  const postFormValues = (values: any) => {
    const postData = userFormPayload(values);
    handlePostApiRequest(postData);
  };

  const handlePostApiRequest = async (data) => {
    const response = await addOrEditOrDeleteUser('add', null, data);

    if (response?.success) {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  return (
    <ReusableForm
       id={'createUserForm'} 
       open={show} 
       onClose={() => setShow(false)} 
       initialValues={initialUserValues} 
       validationSchema={userValidationSchema} 
       onSubmit={handleSubmit} 
       fields={userFields} 
       title={'Register User'} 
       buttonLabel={'Add User'}/>
  )
};

export default CreateUser;
