/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Select,
  FormControl,
  Popover,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  TextField,
  InputAdornment,
  Divider,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CalendarTodayIcon } from '../../../assets/icons/svg/calender.svg';
import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';

import './customDate.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDurationData,
  setStartAndEndDateTime,
} from '../../../redux/ui/uiActions';
import {
  convertAndFormatDate,
  convertToISTFormat,
  dateSlashFormat,
  getDatePart,
  getDateTimeForLabel,
} from '../../../screen/utils/convertTime';
import CONSTANTS from '../../../constants/constants';
import { pushNotification } from '../../../redux/notification/notificationActions';
import { durations } from '../../initial/initialButtons';
import CustomButton from '../customButton';
import { getSessionStorageItem, setSessionStorageItem } from '../../../encrypt';
import { months } from '../../initial/months';

const useStyles = makeStyles((theme) => ({
  datePickerPopover:{
    padding:'1rem',
    [theme.breakpoints.down('sm')]: {
      height:400
    },
    [theme.breakpoints.up('xl')]: {
      height:550
    }
  },
  menuItem: {
    fontSize: '0.8rem !important',
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.95rem !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.5rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem !important',
    },
    borderBottom: '1px solid #e0e0e0 !important',
    '&:last-child': {
      borderBottom: 'none !important', 
    },
  },
  durationSelect: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0.1rem solid #0046FF !important',
      borderRadius: '0.5rem',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      display: 'flex',
      color: '#0046FF', 
      fontSize: '0.9rem !important',
      [theme.breakpoints.up('xs')]: {
        fontSize: '0.9rem !important',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.95rem !important',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem !important',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem !important',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.2rem !important',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.5rem !important',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem !important',
      },
    },
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },

  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  selectRoot: {
    flexGrow: 1,
  },
  downArrow: {
    position: 'absolute',
    right: 1,
    pointerEvents: 'none', 
  },
  dateInput:{
    marginRight:'0.5rem',
    '& .MuiInputBase-root':{
     marginRight:'0.5rem'
    }
}
}));

interface DurationPickerProps {
  setPage: any;
}

const DurationPicker: React.FC<DurationPickerProps> = ({ setPage }) => {
  const classes = useStyles();

  const [selectedDuration, setSelectedDuration] = useState(
    getSessionStorageItem(CONSTANTS.TIME_DURATION) ?? durations[0]?.value
  );

  const [isCustomDate, setIsCustomDate] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { isRefresh } = useSelector((state: any) => state.loaders);
  const [storedCustomDate, setStoredCustomDate] = useState<string>(
    getSessionStorageItem(CONSTANTS.CUSTOM_LABEL) || ''
  );

  const [isFromTo, setIsFromTo] = useState(
    getSessionStorageItem(CONSTANTS.IS_FROM_TO)
      ? getSessionStorageItem(CONSTANTS.IS_FROM_TO) === CONSTANTS.IS_FROM_TO
      : true
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [nextMonth, setNextMonth] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastClickedDate, setLastClickedDate] = useState<any>(null);

  const dispatch = useDispatch();

  const stored: any = getSessionStorageItem(CONSTANTS.CUSTOM_LABEL);

  useEffect(() => {
    if (!isFromTo) {
      setEndDate(startDate);
    }
  }, [isFromTo, startDate]);

  useEffect(() => {
    const changeInDuration = () => {
      const startAndEndDate = getDateTimeForLabel(
        selectedDuration?.split('-')[0]
      );
      dispatch(setStartAndEndDateTime({ ...startAndEndDate }));
    };
    if (!selectedDuration.includes(CONSTANTS.CUSTOM_LABEL)) {
      changeInDuration();
    }

    setStoredCustomDate(stored);
  }, [selectedDuration, dispatch, isRefresh, stored]);

  const renderCalendar = (month, isNextMonth) => {
    const daysInMonth = new Date(
      month?.getFullYear(),
      month?.getMonth() + 1,
      0
    ).getDate();
    const firstDayOfMonth = new Date(
      month?.getFullYear(),
      month?.getMonth(),
      1
    ).getDay();
    const days: any[] = [];

    const prevMonthDays = new Date(
      month?.getFullYear(),
      month?.getMonth(),
      0
    ).getDate();
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      days?.push(
        <div key={`prev-${prevMonthDays - i}`} className="day other-month">
          {prevMonthDays - i}
        </div>
      );
    }

    const today = new Date();
    today?.setHours(0, 0, 0, 0);

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(month?.getFullYear(), month?.getMonth(), i);
      currentDate?.setHours(0, 0, 0, 0);

      const isSelected =
        startDate &&
        endDate &&
        currentDate >= startDate &&
        currentDate <= endDate;
      const isToday = currentDate?.getTime() === today?.getTime();
      const isStart =
        startDate && currentDate?.getTime() === startDate?.getTime();
      const isEnd = endDate && currentDate?.getTime() === endDate?.getTime();
      const isFuture = currentDate > today; 

      let className = 'day';

      if (isSelected || isStart || isEnd) {
        className += ' selected';
        if (isStart) className += ' start';
        if (isEnd) className += ' end';
        if (isSelected && !isStart && !isEnd) className += ' middle';
      }

      if (isToday) className += ' today';
      if (isFuture) className += ' disabled'; 

      days?.push(
        <div
          key={i}
          className={className}
          onClick={!isFuture ? () => handleDateClick(currentDate) : undefined}
        >
          {i}
        </div>
      );
    }

    const totalDays = days?.length;
    for (let i = 1; totalDays + i <= 42; i++) {
      days?.push(
        <div key={`next-${i}`} className="day other-month">
          {i}
        </div>
      );
    }

    return (
      <div className="calendar">
        <div className="calendar-header">
          <div className="month-year-selector">
            <Select
              value={month?.getMonth()}
              onChange={(e) => handleMonthChange(e, isNextMonth)}
              className={classes.select}
            >
              {months?.map((monthName, index) => (
                <MenuItem key={monthName} value={index}>
                  {monthName}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={month?.getFullYear()}
              onChange={(e) => handleYearChange(e, isNextMonth)}
              className={classes.select}
            >
              {years?.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className="weekdays">
          <div>S</div>
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
        </div>
        <div className="days">{days}</div>
      </div>
    );
  };

  const handleDoneClick = () => {
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    startDateTime?.setHours(0, 0, 0);
    endDateTime?.setHours(23, 59, 59);

    const startDateStr = convertToISTFormat(startDateTime);
    const endDateStr = convertToISTFormat(endDateTime);

    setSessionStorageItem(
      CONSTANTS.CUSTOM_LABEL,
      JSON.stringify({
        from: startDateStr,
        to: endDateStr,
      })
    );

    if (startDateStr === endDateStr) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: CONSTANTS.END_DATE_ERROR,
          type: CONSTANTS.ERROR,
        })
      );
    } else {
      dispatch(
        setStartAndEndDateTime({
          from: startDateStr,
          to: endDateStr,
        })
      );

      setAnchorEl(null);
      setIsCustomDate(false);
    }
  };

  const handleDateClick = (date) => {
    if (!isFromTo) {
      setStartDate(date);
      setEndDate(date);
    } else {
      const now: any = new Date();

      if (lastClickedDate && now - lastClickedDate < 300) {
        // Double-click (on selected date) detected
        setStartDate(date);
        setEndDate(date);
        setLastClickedDate(null);
      } else {
        // Single-click(on selected date) behavior
        if (!startDate || date < startDate) {
          setStartDate(date);
          setEndDate(date);
          setLastClickedDate(new Date());
        } else if (date > startDate) {
          setEndDate(date);
          setLastClickedDate(new Date())
        } else {
          if (date?.getTime() === endDate?.getTime()) {
            return;
          } else {
            setEndDate(date);
          }
          setLastClickedDate(new Date());
        }
      }
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth?.setMonth(currentMonth?.getMonth() - 1))
    );
    setNextMonth(new Date(nextMonth?.setMonth(nextMonth?.getMonth() - 1)));
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth?.setMonth(currentMonth?.getMonth() + 1))
    );
    setNextMonth(new Date(nextMonth?.setMonth(nextMonth?.getMonth() + 1)));
  };

  const handleOpenCustomSelectedDate = (e) => {
    setAnchorEl(null);
    setIsCustomDate(true);
  };

  const handleOpenCustomDate = (event) => {
    event.stopPropagation();
    setAnchorEl(event?.currentTarget);
    setIsCustomDate(true);
  };

  const handleTimeDurationChange = (e) => {
    const parsedValue = JSON.parse(e?.target?.value);

    setSelectedDuration(parsedValue?.value);

    setSessionStorageItem(CONSTANTS.TIME_DURATION, parsedValue?.value);

    if (parsedValue?.label === CONSTANTS.CUSTOM_DURATION) {
      handleOpenCustomDate(e);
    }

    dispatch(setDurationData(e?.target?.value));
    setPage(1);
  };

  const handleCloseCustomDate = () => {
    setAnchorEl(null);
    setIsCustomDate(false);
  };

  const handleMonthChange = (event, isNextMonth) => {
    const newMonth = new Date(isNextMonth ? nextMonth : currentMonth);
    newMonth?.setMonth(event?.target?.value);
    if (isNextMonth) {
      setNextMonth(newMonth);
    } else {
      setCurrentMonth(newMonth);
      setNextMonth(
        new Date(new Date(newMonth)?.setMonth(newMonth?.getMonth() + 1))
      );
    }
  };

  const handleYearChange = (event, isNextMonth) => {
    const newMonth = new Date(isNextMonth ? nextMonth : currentMonth);
    newMonth?.setFullYear(event?.target?.value);
    if (isNextMonth) {
      setNextMonth(newMonth);
    } else {
      setCurrentMonth(newMonth);
      setNextMonth(
        new Date(new Date(newMonth)?.setMonth(newMonth?.getMonth() + 1))
      );
    }
  };

  const years = Array.from(
    { length: 10 },
    (_, i) => new Date().getFullYear() + i - 5
  );

  return (
    <div className="custom-duration-select">
      <FormControl variant="outlined" fullWidth>
        <div className={classes.selectWrapper}>
          <Select
            inputProps={{ 'data-testid': 'duration-select-label' }}
            className={`duration-select-component ${classes.selectRoot} ${classes.durationSelect}`}
            labelId="duration-select-label"
            value={selectedDuration}
            onChange={handleTimeDurationChange}
            label=""
            renderValue={(selected) => {
              const selectedOption = durations?.find(
                (option) => option?.value === selected
              );
              return selectedOption ? selectedOption?.label : '';
            }}
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => setIsDropdownOpen(false)}
          >
            {durations?.map((option) => (
              <MenuItem
                className={classes.menuItem}
                key={option?.label}
                value={JSON.stringify(option)}
              >
                <div className='duration-menu-items'>
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={selectedDuration === option?.value}
                      className='radio-button'
                    />
                    {option?.label}
                  </Box>
                  {option?.label ===CONSTANTS.CUSTOM_DURATION &&
                    selectedDuration?.includes(CONSTANTS.CUSTOM_LABEL) && (
                      <div
                        className="custom-date-range"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenCustomSelectedDate(e);
                        }}
                      >
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <CalendarTodayIcon />
                          <Box
                            width={'100%'}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                          >
                            {isFromTo ? (
                              <>
                                {getSessionStorageItem(
                                  CONSTANTS.CUSTOM_LABEL
                                ) ? (
                                  <>
                                    <span className="selected-date">
                                      {getDatePart(
                                        JSON.parse(
                                          getSessionStorageItem(
                                            CONSTANTS.CUSTOM_LABEL
                                          ) as any
                                        )?.from ||
                                          convertAndFormatDate(startDate)
                                      )}
                                    </span>
                                    <span className="selected-date">to</span>
                                    <span className="selected-date">
                                      {getDatePart(
                                        JSON.parse(
                                          getSessionStorageItem(
                                            CONSTANTS.CUSTOM_LABEL
                                          ) as any
                                        )?.to || convertAndFormatDate(endDate)
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {storedCustomDate ? (
                                      <span className="selected-date">
                                        {getDatePart(
                                          JSON.parse(
                                            getSessionStorageItem(
                                              CONSTANTS.CUSTOM_LABEL
                                            ) as any
                                          )?.from ||
                                            convertAndFormatDate(startDate)
                                        )}
                                      </span>
                                    ) : (
                                      convertAndFormatDate(startDate)
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <span>
                                {storedCustomDate ? (
                                  <span className="selected-date">
                                    {getDatePart(
                                      JSON.parse(
                                        getSessionStorageItem(
                                          CONSTANTS.CUSTOM_LABEL
                                        ) as any
                                      )?.from || convertAndFormatDate(startDate)
                                    )}
                                  </span>
                                ) : (
                                  convertAndFormatDate(startDate)
                                )}
                              </span>
                            )}
                          </Box>
                        </Box>
                      </div>
                    )}
                </div>
              </MenuItem>
            ))}
          </Select>
          <DownArrow
            style={{
              transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            className={classes.downArrow}
          />
        </div>
      </FormControl>
      <Popover
        className="date-picker-modal"
        open={isCustomDate}
        anchorEl={anchorEl}
        onClose={handleCloseCustomDate}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          className={`date-picker-container ${classes.datePickerPopover}`}
        >
          <div className="date-picker-content">
            <div className="date-picker-header">
              <FormControl className="radio-group-and-date-container">
                <RadioGroup
                  row
                  value={isFromTo ? CONSTANTS.IS_FROM_TO : CONSTANTS.TODAY}
                  onChange={(e) => {
                    setIsFromTo(e?.target?.value === CONSTANTS.IS_FROM_TO);
                    setSessionStorageItem(
                      CONSTANTS.IS_FROM_TO,
                      e?.target?.value
                    );
                    if (e?.target?.value === CONSTANTS.TODAY) {
                      const today = new Date();
                      setStartDate(today);
                      setEndDate(today);
                    }
                  }}
                  className="date-type-selector"
                >
                  <FormControlLabel
                    className="custom-form-control-label"
                    value="fromTo"
                    control={<Radio />}
                    label={CONSTANTS.FROM_TO_TEXT}
                  />
                  <FormControlLabel
                    data-testid="singleDay"
                    className="custom-form-control-label"
                    value="today"
                    control={<Radio />}
                    label={CONSTANTS.SINGLE_DAY_TEXT}
                  />
                </RadioGroup>
              </FormControl>
              <div className="date-btn-container">
                <TextField
                  
                  className={classes.dateInput}
                  size="small"
                  value={
                    isFromTo
                      ? `${dateSlashFormat(startDate)} - ${dateSlashFormat(
                          endDate
                        )}`
                      : dateSlashFormat(startDate)
                  }
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleCloseCustomDate}>
                          <CalendarTodayIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomButton id="doneBtn" label={CONSTANTS.DONE_BTN_TEXT} onClick={handleDoneClick}/>
              </div>
            </div>
            <Divider className="duration-picker-divider" />
            <div className="calendar-container">
              <div className="calendars">
                <IconButton
                  className="nav-button prev"
                  onClick={handlePrevMonth}
                >
                  <ArrowBackIosNewIcon data-testid="ArrowBackIosNewIcon" />
                </IconButton>
                {renderCalendar(currentMonth, false)}
                {renderCalendar(nextMonth, true)}
                <IconButton
                  className="nav-button next"
                  onClick={handleNextMonth}
                >
                  <ArrowForwardIosIcon data-testid="ArrowForwardIosIcon" />
                </IconButton>
              </div>
            </div>
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default DurationPicker;
