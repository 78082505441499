/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';
import { Pagination } from '@mui/material';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  pagination: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  paginationUl: {
    '& .MuiPaginationItem-root': {
      fontWeight: 600,
      '&.Mui-selected': {
        border: '0.81px solid #0046FF',
        color: '#0046FF',
        '&:hover': {
          backgroundColor: '#0046FF',
          color: '#FFFFFF',
        },
      },
      '&.MuiPaginationItem-page.Mui-selected + .MuiPaginationItem-ellipsis': {
        borderColor: '#0046FF',
      },
      '&.MuiPaginationItem-previousNext.Mui-disabled': {
        opacity: 0.5,
      },
      '&.MuiPaginationItem-previousNext:not(.Mui-disabled)': {
        backgroundColor: '#0046FF',
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#0046FF',
        },
      },
    },
  },
}));

interface PaginationProps {
  totalPages: number;
  page: number;
  handleChangePage: any;
}

const CustomPagination: React.FC<PaginationProps> = ({
  totalPages,
  page,
  handleChangePage,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.pagination}>
      <Pagination
        page={page}
        count={totalPages}
        onChange={handleChangePage}
        variant="outlined"
        shape="rounded"
        classes={{ ul: classes.paginationUl }}
      />
    </div>
  );
};

export default CustomPagination;
