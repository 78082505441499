/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Box, IconButton } from '@mui/material';
import geoJsonData from '../../../components/initial/geo_map_topo_json.json';
import { formatNumber } from '../../utils/formatNumber';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './GeoMap.css';
import { ALL_COUNTRY_SHORT_CODES } from '../../../constants/countryCodes';
import { makeStyles } from '@material-ui/core';
import { getRandomPastelColor } from '../../utils/getColor';
import { MapChartProps } from './type';
import { processHeatMapData } from '../../utils/processData';
import CONSTANTS from '../../../constants/constants';

const TRAFFIC_STOP_COLOR = '#3F93FF';
const TRAFFIC_START_COLOR = '#A1FF8B';
const ATTACK_START_COLOR = '#FBDA61';
const ATTACK_STOP_COLOR = '#FF4B55';
const GLOW_COLOR = '#E4E7EC';

const TRAFFIC_GRADIENT =
  'linear-gradient(135deg, #A1FF8B 2.88%, #3F93FF 98.13%)';
const ATTACK_GRADIENT =
  'linear-gradient(135deg, #FBDA61 2.88%, #FF4B55 98.13%)';
const ATTACK_FILTER = 'attack'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '90%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: '#E3F2FD',
    borderRadius: '0.5rem',
    '@media (min-width:2560px)': {
      height: '88%',
    },
  },
  svgContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  svg: {
    position: 'absolute',
    top: '50%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    '@media (min-width:2560px)': {
      width: '90%',
      left: '40%',
      transform: 'translate(-40%, -50%)',
    },
  },
  zoomButtonContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  zoomButton: {
    height: '1.8rem',
    width: '1.8rem',
    borderRadius: '0 !important',
    backgroundColor: '#FFFFFF !important',
    boxShadow: '0px 1.73px 4.34px 0px #0000001A',
    color: '#0D46C1 !important',
    '@media (min-width:2560px)': {
      height: '2.8rem',
      width: '2.8rem',
    },
    '@media (min-width:3840px)': {
      height: '3rem',
      width: '3rem',
    },
  },
  zoomInButton: {
    borderTopLeftRadius: '0.5rem !important',
    borderTopRightRadius: '0.5rem !important',
    borderBottom: '1px solid #D8D8D8 !important',
  },
  zoomOutButton: {
    borderBottomLeftRadius: '0.5rem !important',
    borderBottomRightRadius: '0.5rem !important',
  },
  tooltip: {
    position: 'absolute',
    textAlign: 'center',
    width: 'auto',
    height: 'auto',
    padding: '8px',
    fontSize: '12px',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    borderRadius: '4px',
    pointerEvents: 'none',
    zIndex: 10,
    opacity: 0,
    transition: 'opacity 0.2s',
  },
  customTooltip: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    background: '#fff',
    color: '#000',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    pointerEvents: 'none',
    zIndex: 10,
    opacity: 1,
    transition: 'opacity 0.2s',
  },
  tooltipArrow: {
    width: 0,
    height: 1,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #fff',
    position: 'absolute',
    bottom: '-2px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  tooltipFlag: {
    width: '24px',
    height: '18px',
    backgroundColor: '#E3F2FD',
    padding: 2,
  },
  tooltipTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  tooltipCountry: {
    color: '#475467',
    fontSize: '0.7rem',
  },
  tooltipCount: {
    fontSize: '0.9rem',
  },
  gradientContainer: {
    position: 'absolute',
    bottom: 18,
    right: 16,
    width: '2rem',
    height: '10rem',
    background: 'linear-gradient(to bottom, #FBDA61 2.88%, #FF4B55 98.13%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
  gradientText: {
    color: '#000',
    fontSize: '0.8rem',
  },
  icon: {
    fontSize: '1rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.6rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '2rem !important',
    },
  },
});

const GeoMap: React.FC<MapChartProps> = ({ id, traffic, heatMapData }) => {
  const classes = useStyles();


  const STOP_COLOR =
    traffic === ATTACK_FILTER ? ATTACK_STOP_COLOR : TRAFFIC_STOP_COLOR;
  const START_COLOR =
    traffic === ATTACK_FILTER ? ATTACK_START_COLOR : TRAFFIC_START_COLOR;

  const gradientBackground =
    traffic === ATTACK_FILTER ? ATTACK_GRADIENT : TRAFFIC_GRADIENT;

  const mapContainerRef = useRef<any>(null);
  const gradientBarRef = useRef<SVGRectElement | null>(null);
  const gradientBarTextMinRef = useRef<SVGTextElement | null>(null);
  const gradientBarTextMaxRef = useRef<SVGTextElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [isZoomingIn, setIsZoomingIn] = useState(false);
  const [isZoomingOut, setIsZoomingOut] = useState(false);
  const zoomRef = useRef<d3.ZoomBehavior<Element, unknown>>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentZoom, setCurrentZoom] = useState(1);



  const counts = heatMapData?.map((item) => item?.count);
  const minCount = Math.min(...counts);
  const maxCount = Math.max(...counts);

  const handleZoom = useCallback((direction: 'in' | 'out') => {
    if (!zoomRef?.current) return;

    const svg = d3?.select(mapContainerRef?.current);
    const currentTransform = d3?.zoomTransform(svg?.node() as Element);

    const newScale =
      direction === 'in'
        ? currentTransform?.k * 1.05
        : currentTransform?.k / 1.05;

    zoomRef?.current?.scaleTo(svg, newScale);
  }, []);

  useEffect(() => {
    let animationFrameId: number;

    const animate = () => {
      if (isZoomingIn) {
        handleZoom('in');
      } else if (isZoomingOut) {
        handleZoom('out');
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    if (isZoomingIn || isZoomingOut) {
      animate();
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [handleZoom, isZoomingIn, isZoomingOut]);

  useEffect(() => {
    const svg = d3.select(mapContainerRef?.current);
    const gradientBar = d3.select(gradientBarRef?.current);
    const gradientBarTextMin = d3.select(gradientBarTextMinRef?.current);
    const gradientBarTextMax = d3.select(gradientBarTextMaxRef?.current);

    const processedHeatMapData = processHeatMapData(heatMapData);

    const handleResize = () => {
      const container = svg?.node()?.parentNode;
      const containerWidth = container?.clientWidth;
      const containerHeight = container?.clientHeight;

      const originalWidth = 800;
      const originalHeight = 500;

      const scaleX = containerWidth / originalWidth;
      const scaleY = containerHeight / originalHeight;
      const scale = Math.min(scaleX, scaleY) * 1.25;

      const newWidth = originalWidth * scale;
      const newHeight = originalHeight * scale;

      const projection = d3
        .geoNaturalEarth1()
        .fitSize([newWidth, newHeight], geoJsonData);
      const path = d3.geoPath().projection(projection);

      svg.attr('width', newWidth).attr('height', newHeight);

      projection.fitSize([newWidth, newHeight], geoJsonData);
      svg.selectAll('g').remove(); 

      const countryGroup = svg.append('g').attr('id', 'country-paths-group');

      countryGroup
        .selectAll('path')
        .data(geoJsonData.features)
        .enter()
        .append('path')
        .attr('d', path)
        .style('fill', (d) => {
          
          const value = processedHeatMapData[d?.id];
          const maxValue = d3?.max(Object?.values(processedHeatMapData)) || 100;
          const colorScale = d3
            .scaleLinear()
            .domain([0, maxValue])
            .range([START_COLOR, STOP_COLOR]);

          return value ? colorScale(value) : '#FFFFFF';
        })
        .style('stroke', '#E4E7EC');

      const labelGroup = svg.append('g').attr('id', 'label-group');

      const defs = svg.append('defs');
      const filter = defs
        .append('filter')
        .attr('id', 'custom-shadow')
        .attr('x', '-50%')
        .attr('y', '-50%')
        .attr('width', '200%')
        .attr('height', '200%');

      filter
        .append('feDropShadow')
        .attr('dx', '6.33')
        .attr('dy', '7.69')
        .attr('stdDeviation', '4.52')
        .attr('flood-color', '#7090B0')
        .attr('flood-opacity', '0.6');

      filter
        .append('feDropShadow')
        .attr('dx', '0')
        .attr('dy', '4.52')
        .attr('stdDeviation', '4.52')
        .attr('flood-color', '#000000')
        .attr('flood-opacity', '0.2');

      labelGroup
        .selectAll('.country-label')
        .data(heatMapData)
        .enter()
        .append('g')
        .attr('class', 'country-label')
        .attr('transform', (d) => {
          const feature = geoJsonData?.features?.find(
            (f) => f?.id === d?.countryCode
          );
          if (feature) {
            const centroid = path?.centroid(feature);
            return `translate(${centroid[0] - 30}, ${centroid[1] - 50})`;
          }
          return '';
        })
        .each(function (d, i, nodes) {
          const label = d3.select(nodes[i]);
          const padding = 2;
          const countryData = ALL_COUNTRY_SHORT_CODES?.find(
            (c) => c?.cca2 === d?.countryCode
          );
          const flag = countryData ? countryData?.flag : '';

          const bgRect = label
            .append('rect')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('fill', countryData ? '#FFFFFF' : 'transparent')
            .attr('stroke', countryData ? '#E4E7EC' : '')
            .attr('stroke-width', 1)
            .attr('filter', 'url(#custom-shadow)');

          const flagGroup = label.append('g').attr('class', 'flag-group');

          flagGroup
            .append('rect')
            .attr('x', padding)
            .attr('y', padding)
            .attr('width', 20)
            .attr('height', 20)
            .attr('rx', 2)
            .attr('ry', 2)
            .attr('fill', countryData ? getRandomPastelColor() : 'none');

          flagGroup
            .append('text')
            .attr('x', padding + 10)
            .attr('y', padding + 14)
            .attr('font-size', 12)
            .attr('text-anchor', 'middle')
            .text(flag);

          const countryText = label
            .append('text')
            .attr('x', padding + 22)
            .attr('y', padding + 10)
            .attr('font-size', 10)
            .attr('fill', '#666')
            .text(d?.countryName);

          const countText = label
            .append('text')
            .attr('x', padding + 22)
            .attr('y', padding + 22)
            .attr('font-size', 12)
            .text(countryData ? formatNumber(d?.count) : '');

          const countryWidth = countryText.node().getComputedTextLength();
          const countWidth = countText.node().getComputedTextLength();
          const maxWidth = Math.max(countryWidth, countWidth);

          bgRect
            .attr('width', maxWidth + 28 + padding * 2)
            .attr('height', 28 + padding * 2);

          label
            .append('path')
            .attr(
              'd',
              `M${(maxWidth + 28 + padding * 2) / 2 - 4},${28 + padding * 2} L${
                (maxWidth + 28 + padding * 2) / 2 + 4
              },${28 + padding * 2} L${(maxWidth + 28 + padding * 2) / 2},${
                32 + padding * 2
              } Z`
            )
            .attr('fill', '#FFFFFF')
            .attr('stroke', '#E4E7EC')
            .attr('stroke-width', 1);

          label
            .append('line')
            .attr('x1', (maxWidth + 28 + padding * 2) / 2)
            .attr('y1', 32 + padding * 2)
            .attr('x2', (maxWidth + 28 + padding * 2) / 2)
            .attr('y2', 50 + padding * 2)
            .attr('stroke', countryData ? '#999' : '')
            .attr('stroke-width', 1);

          label
            .append('rect')
            .attr('width', maxWidth + 28 + padding * 2)
            .attr('height', 32 + padding * 2)
            .attr('fill', 'transparent')
            .style('cursor', 'pointer');

          let originalTransform;

          label
            .on('mouseenter', (event) => {
              const hoveredLabel = d3.select(event?.currentTarget);
              originalTransform = hoveredLabel.attr('transform');
              hoveredLabel?.raise(); 
              const scaledTransform = `${originalTransform} scale(1.1)`;
              hoveredLabel
                .transition()
                .duration(200)
                .attr('transform', scaledTransform);
            })
            .on('mouseleave', (event) => {
              const hoveredLabel = d3.select(event?.currentTarget);
              hoveredLabel
                .transition()
                .duration(200)
                .attr('transform', originalTransform);
            });
        });

      const {
        gradientBarX,
        gradientBarY,
        gradientBarWidth,
        gradientBarHeight,
      } = handleGradientBar(newWidth, newHeight);

      gradientBar
        .attr('x', gradientBarX)
        .attr('y', gradientBarY)
        .attr('width', gradientBarWidth)
        .attr('height', gradientBarHeight);

      const textOffset = 15;

      const isMinMaxEqual =
        d3.min(Object?.values(processedHeatMapData)) ===
        d3.max(Object?.values(processedHeatMapData));
      gradientBarTextMin
        .attr('x', gradientBarX + gradientBarWidth)
        .attr('y', gradientBarY - textOffset)
        .attr('text-anchor', 'middle')
        .text(
          isMinMaxEqual
            ? 0
            : formatNumber(d3.min(Object?.values(processedHeatMapData))) || ''
        )
        .on('mouseover', () => {
          gradientBarTextMin.text(
            d3.min(Object?.values(processedHeatMapData)) || ''
          );
        })
        .on('mouseout', () => {
          gradientBarTextMin.text(
            isMinMaxEqual
              ? 0
              : formatNumber(d3?.min(Object?.values(processedHeatMapData))) || ''
          );
        });

      gradientBarTextMax
        .attr('x', gradientBarX + gradientBarWidth / 2)
        .attr('y', gradientBarY + gradientBarHeight + textOffset)
        .attr('text-anchor', 'middle')
        .text(formatNumber(d3.max(Object?.values(processedHeatMapData))) || '')
        .on('mouseover', () => {
          gradientBarTextMax.text(
            d3.max(Object?.values(processedHeatMapData)) || ''
          );
        })
        .on('mouseout', () => {
          gradientBarTextMax.text(
            formatNumber(d3.max(Object?.values(processedHeatMapData))) || ''
          );
        });

      zoomRef.current = d3.zoom().on('zoom', (event) => {
        if (
          event.sourceEvent &&
          (event.sourceEvent.type === CONSTANTS.WHEEL_TEXT ||
            event.sourceEvent.type === CONSTANTS.DOUBLE_CLICK_TEXT)
        ) {
          return; 
        }
        countryGroup.attr('transform', event.transform);
        labelGroup.attr('transform', event.transform);
      });

      svg
        .call(zoomRef.current)
        .on('wheel.zoom', null)
        .on('dblclick.zoom', null);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [heatMapData, id, STOP_COLOR, START_COLOR, traffic]);

  const handleGradientBar = (newWidth: number, newHeight: number) => {
    const gradientBarX = newWidth - 30;
    const gradientBarY = newHeight - 190;
    const gradientBarHeight = 140;
    const gradientBarWidth = 28;

    return { gradientBarX, gradientBarY, gradientBarWidth, gradientBarHeight };
  };

  const removeIconStyles = { color: !currentZoom ? '#D9D9D9' : '#0046FF' }

  return (
    <Box id={id} className={classes.root}>
      <Box component="div" className={classes.svgContainer}>
        <svg
          ref={mapContainerRef}
          id={`svgContainer-${id}`}
          className={classes.svg}
          filter="url(#glow)"
        >
          <defs>
            <filter
              id="glow"
              filterUnits="objectBoundingBox"
              colorInterpolationFilters="sRGB"
            >
              <feGaussianBlur stdDeviation="20" result="coloredBlur" />
              <feFlood floodColor={GLOW_COLOR} result="glowColor" />
              <feComposite
                in="glowColor"
                in2="coloredBlur"
                operator="in"
                result="coloredGlow"
              />
              <feMerge>
                <feMergeNode in="coloredGlow" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
        </svg>
        <div ref={tooltipRef} className={classes.tooltip} />
      </Box>

      <Box className={classes.zoomButtonContainer}>
        <IconButton
          onMouseDown={() => {
            setIsZoomingIn(true);
          }}
          onMouseUp={() => setIsZoomingIn(false)}
          onMouseLeave={() => setIsZoomingIn(false)}
          className={`${classes.zoomButton} ${classes.zoomInButton}`}
        >
          <AddIcon className={classes.icon} />
        </IconButton>
        <IconButton
          
          disabled={!currentZoom}
          onMouseDown={() => {
            setIsZoomingOut(true);
          }}
          onMouseUp={() => setIsZoomingOut(false)}
          onMouseLeave={() => setIsZoomingOut(false)}
          className={`${classes.zoomButton} ${classes.zoomOutButton}`}
        >
          <RemoveIcon
            className={classes.icon}
            sx={removeIconStyles}
          />
        </IconButton>
      </Box>
      <div
        id={`${id}-gradientBarContainer`}
        data-testid="geoMapGradientBar"
        className="gradient-bar"
      >
        <div
          id={`${id}-gradientBar`}
          style={{ background: gradientBackground }}
          className="gradient-rectangle"
        >
          <div id={`${id}-gradientBarMinValue`} className="min-label">
            {formatNumber(minCount)}
          </div>
          <div
            id={`${id}-gradientBarMaxValue`}
            className={
              maxCount >= 100 ? 'max-label' : 'max-label max_label-single-digit'
            }
          >
            {formatNumber(maxCount)}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default GeoMap;
