/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as DashboardIcon } from '../../../assets/icons/svg/dashboardIcon.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/svg/user.svg';
import { ReactComponent as ExitToAppIcon } from '../../../assets/icons/svg/logoutIcon.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/svg/userIcon.svg';
import { ReactComponent as DashboardBoldIcon } from '../../../assets/icons/svg/dashboardIconBold.svg';
import { ReactComponent as CRSIcon } from '../../../assets/icons/svg/crs.svg';

import './Sidebar.css';
import CONSTANTS, { MENU_PATHS } from '../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLogout } from '../../../screen/utils/logout';
import {
  getUserProfileImage,
  userProfilePageApi,
} from '../../../services/profileApi';
import { getSessionStorageItem } from '../../../encrypt';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  
  menuContainer: {
    border: '2px solid blue',
    width: '100%',
    height: 'calc(100% - 70px) !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  listItem: {
    '&:hover': {
      width: drawerWidth - 20,
      backgroundColor: '#CCDAFF !important',
      borderRadius: '0.5rem !important',
      color: '#FFFFFF',
    },

    '&.Mui-selected': {
      color: '#FFFFFF',
      width: drawerWidth - 20,
      marginLeft: 8,
      backgroundColor: '#0046FF !important',
      borderRadius: '0.5rem !important',
      '&:hover': {
        backgroundColor: '#0038CC !important',
      },
    },
  },
  listItemText: {
    paddingLeft: 8,
  },
  listItemCollapsed: {
    '&.Mui-selected': {
      backgroundColor: '#0046FF !important',
      marginLeft: 10,
      paddingLeft: 8,
      borderRadius: '0.5rem',
    },
  },
  avatar: {
    width: '2rem !important',
    height: '2rem !important',
    objectFit: 'cover',
    borderRadius: 50,
    display: 'block',
    '@media (min-width:2560px)': {
      width: '2.4rem !important',
      height: '2.4rem !important',
    },
  },
  profileBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: 150,
    paddingLeft: theme.spacing(3),
  },
  name: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
}));

interface SidebarProps {
  id: string;
  open: boolean;
  handleMouseLeave: any;
  handleMouseEnter: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  id,
  open,
  handleMouseLeave,
  handleMouseEnter,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const userType =
    getSessionStorageItem(CONSTANTS.USER_TYPE) === CONSTANTS.WAF_ADMIN;

  const dispatch = useDispatch();
  const { isWafAdmin } = useSelector((state: any) => state?.authentication);
  const { userPreferences, userProfileData } = useSelector(
    (state: any) => state?.profile
  );
  const { domain } = useSelector((state: any) => state?.domainData);

  const isDomainsAvailable = !domain?.domain_id;

  const isDashboardVisible = userType ? isDomainsAvailable && userType : false;
  const handleNavigation = (path) => {
    navigate(path);
  };

  const listItems = [
    {
      text: 'Dashboard',
      icon: <DashboardIcon />,
      hasAccess: true,
      class: 'sidebar_dashboard-icon',
      disabled: isDashboardVisible,
      boldIcon: <DashboardBoldIcon />,
      path: CONSTANTS.NAVIGATE_TO_DASHBOARD,
    },
    {
      text: 'Clients',
      icon: <PeopleIcon />,
      hasAccess: isWafAdmin,
      path: `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CLIENTS}`,
    },
    {
      text: 'Users',
      icon: <PeopleIcon />,
      hasAccess: isWafAdmin,
      path: `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USERS}`,
    },
    {
      text: 'CRS',
      icon: <CRSIcon />,
      hasAccess: isWafAdmin,
      path: `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CORE_RULE_SET}`,
    },
  ];

  const commonMenus = [
    {
      text: CONSTANTS.LOGOUT,
      icon: <ExitToAppIcon />,
      class: 'logout-icon',
      path: MENU_PATHS.LOGOUT,
    },
  ];

  const username = getSessionStorageItem(CONSTANTS.FIRST_NAME);
  const company = userProfileData?.data?.client_details?.client_name;

  const handleLogout = useLogout();

  useEffect(() => {
    switch (window?.location?.pathname) {
      case `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CLIENTS}`:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CLIENTS}`);
        break;

      case `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USERS}`:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USERS}`);
        break;
      case `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USER_PROFILE}`:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USER_PROFILE}`);
        break;
      case `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CORE_RULE_SET}`:
        userType
          ? navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CORE_RULE_SET}`)
          : navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`);
        break;
      case CONSTANTS.NAVIGATE_TO_DASHBOARD:
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`);
        break;

      default:
        isDashboardVisible
          ? navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CLIENTS}`)
          : navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardVisible, navigate]);

  useEffect(() => {
    getUserProfileImage(dispatch);
    userProfilePageApi(dispatch);
  }, [dispatch]);

  const drawerStyles = {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: () => ({
        xs: open ? '12rem' : '4rem',
        md: open ? '14rem' : '4.2rem',
        lg: open ? '16rem' : '4.2rem',
        xl: open ? '17rem' : '4.2rem',
      }),
      transition: 'width 0.4s',
      overflowX: 'hidden',
      border: 'none',
      marginTop: {
        xs: 7.6,
        sm: 7,
        md: 7.5,
      },
    },
  };

  const listItemStyles = {
    width: { xs: 44, xl: 44 },
    height: { xs: 44, xl: 44 },
    mt: 2, 
    mb: 2, 
    ml: () => (open ? 1 : 0.5), 
  };

  const commonListItemStyles = {
    width: 40,
    height: 40,      
    mt: 1,         
    mb: 1,       
    ml: () => (open ? 1 : 0.5),
  };
  

  return (
    <Drawer
      id={id}
      variant={'permanent'}
      open={true}

      sx={drawerStyles}
      onClose={handleMouseLeave}
      ModalProps={{
        keepMounted: true,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={id}
    >
      
      <Box id={`${id}-menuContainer`} className="sidebar_menu-container">
        <List>
          <Box
            id={`${id}-profileIcon`}
            onClick={() =>
              handleNavigation(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USER_PROFILE}`)
            }
            className="sidebar_menu-profile-icon"
          >
            {userPreferences?.profilePicture ? (
              <img
                alt="User Avatar"
                src={`data:image/png;base64,${userPreferences?.profilePicture}`}
                className={classes.avatar}
              />
            ) : (
              <ListItemIcon className="sidebar_icon">
                <UserIcon />
              </ListItemIcon>
            )}
            <Box pl={3} maxWidth={150}>
              <Typography
                fontWeight={600}
                className={classes.name}
                id={`${id}-username`}
                variant="body2"
              >
                {username}
              </Typography>
              <Typography
                className={classes.name}
                textTransform={'capitalize'}
                id={`${id}-companyName`}
                variant="body2"
                color="textSecondary"
              >
                {company}
              </Typography>
            </Box>
          </Box>

          {listItems
            ?.filter((menuItem) => menuItem?.hasAccess)
            ?.map((item, index) => {
              return (
                <ListItem
                  id={`${id}-menuItem-${index}`}
                  disableRipple
                  disableTouchRipple
                  button
                  key={`${id}-menuItem-${index}`}
                  onClick={() => handleNavigation(item?.path)}
                  selected={location?.pathname === item?.path}
                  disabled={item?.disabled}
                  className={
                    open ? classes.listItem : classes.listItemCollapsed
                  }
                  sx={listItemStyles}
                >
                  <ListItemIcon
                    id={`${id}-menuIcon-${index}`}
                    className={
                      location?.pathname === item?.path
                        ? `sidebar_icon sidebar_menu-icon ${item?.class}`
                        : 'sidebar_icon'
                    }
                  >
                    {location?.pathname === item?.path
                      ? item?.icon
                      : item?.boldIcon || item?.icon}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      id={`${id}-menuText-${index}`}
                      className={classes.listItemText}
                      primary={item?.text}
                    />
                  )}
                </ListItem>
              );
            })}
        </List>
        <List>
          {commonMenus?.map((item, index) => (
            <ListItem
              id={`${id}-commonMenuItem-${index}`}
              disableRipple
              button
              key={index}
              onClick={
                item?.text === CONSTANTS.LOGOUT
                  ? handleLogout
                  : () => handleNavigation(item?.path)
              }
              selected={location?.pathname === item?.path}
              className={open ? classes.listItem : classes.listItemCollapsed}
              sx={commonListItemStyles}
            >
              <ListItemIcon
                id={`${id}-commonMenuItemIcon-${index}`}
                className={
                  location?.pathname === item?.path
                    ? `logout-icon-svg ${item?.class}`
                    : 'logout-icon-svg'
                }
              >
                {item?.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  id={`${id}-commonMenuItemText-${index}`}
                  className={classes.listItemText}
                  primary={item?.text}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
