/* eslint-disable prettier/prettier */
import React from 'react';
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactComponent as SearchIcon } from '../../../assets/icons/svg/Search.svg';
import { makeStyles } from '@material-ui/core';
import { useSharedStyles } from '../../../styles/reuseStyles';

const useStyles = makeStyles(() => ({
    searchField: {
        '& .MuiOutlinedInput-root': {
           padding: '0.5rem 1rem',
          '& fieldset': {
            borderColor: '#C4C4C4',
          },
          '&:hover fieldset': {
            borderColor: '#0046FF',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#0046FF',
          },
        },
        '& .MuiInputLabel-root': {
          color: '#0046FF',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#C4C4C4',
        },
        
      },
      icon:{
        cursor:'pointer'
      }
}))
interface CustomSearchFieldProps extends Omit<TextFieldProps, 'onChange'> {
    id:string;
    value: string;
    onChange: any;
    onClear?: () => void;
    placeholder: string;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    fullWidth?: boolean;
    handleSearch?: any;
  }
  
const CustomSearchField: React.FC<CustomSearchFieldProps> = ({
  id,
  value,
  onChange,
  onClear,
  placeholder = 'Search...',
  startAdornment,
  endAdornment,
  handleSearch,
  fullWidth = true,
  ...props
}) => {
  const handleClear = () => {
    if (onClear) {
      onClear();
    } else {
      onChange({ target: { value: '' } });
    }
  };

  const classes = useStyles()
  const sharedClasses = useSharedStyles();


  return (
    <TextField
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      fullWidth={fullWidth}
      variant="outlined"
      className={`${classes.searchField} ${sharedClasses.commonHeight}`}
      size="small"
      InputProps={{
        classes: { root: sharedClasses.commonHeight },

        startAdornment: startAdornment || (
          <InputAdornment onClick={handleSearch} position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
        endAdornment:
          endAdornment ||
          (value && (
            <InputAdornment onClick={handleClear} position="end" data-testid={`${id}-clear-icon`}>
              <ClearIcon  className={classes.icon} />
            </InputAdornment>
          )),
      }}
      {...props}
    />
  );
};

export default CustomSearchField;
