export const getSeverityColor = (severity) => {
    switch (severity.toLowerCase()) {
      case 'high': return '#F46B73';
      case 'medium': return '#F9C235';
      case 'low': return '#5BAE65';
      default: return 'default';
    }
  };


export const getStatusColor = (status) => 'currentColor';


export const owaspTop10Colors = [
  '#14B8A6',
  '#3B82F6',
  '#6366F1',
  '#A855F7',
  '#FAA307',
  '#FFEA00',
  '#80B918',
  '#2B9348',
  '#023E8A',
  '#FB5607',
  '#0077B6',
  '#EC4899',
  '#F59E0B',
  '#FACC15',
  '#F59E0B',
  '#F59E0C',
  
];


export const getRandomPastelColor = ()=> {
  const hue = Math.floor(Math.random() * 100);
  return `hsl(${hue}, 80%, 50%)`;
}