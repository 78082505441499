/* eslint-disable prettier/prettier */
import wretch from 'wretch';
import { ResponseChain } from 'wretch/dist/resolver';
import CONSTANTS from '../constants/constants';
import { getSessionStorageItem } from '../encrypt';
import { TEST_CONSTANTS } from '../constants/testConstants';

export interface ResponseProps {
  success?: boolean;
  data?: unknown;
  message?: string;
}

type ApiResponse = unknown;

export const callAPI = async (w: ResponseChain): Promise<ApiResponse> => {
  return w
    .unauthorized((_error) => {
      sessionStorage.clear();
      return {
        success: false,
        message: CONSTANTS.PLEASE_LOGIN_MSG,
      };
    })

    .internalError((error) => {
      const { message } = JSON.parse(error.message);
      return {
        success: false,
        message: message,
      };
    })
    .json((response) => response)
    .catch((error) => {
      if (error && error.message) {
        try {
          return JSON.parse(error.message);
        } catch {
          return {
            success: false,
            message: CONSTANTS.SERVER_ERROR_MSG,
          };
        }
      }

      return {
        success: false,
        message:CONSTANTS.SERVER_ERROR_MSG,
      };
    });
};

export const callBlobAPI = async (wr: ResponseChain): Promise<ApiResponse> => {
  return wr
    .unauthorized(() => {
      sessionStorage.clear();
      return {
        success: false,
        message: TEST_CONSTANTS.PLEASE_LOGIN_AGAIN_MWG,
      };
    })
    .blob((response) => {
      return { success: true, data: response };
    })
    .catch((_error) => {
      return {
        success: false,
        message: CONSTANTS.SERVER_ERROR_MSG,
      };
    });
};

export interface ResponseProps {
  success?: boolean;
  data?: unknown;
  message?: string;
}

export const request = {
  get: (url: string): Promise<ApiResponse> => {
    return callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .headers({ 'content-type': 'application/json' })
        .get()
    );
  },
  getBlob: (url: string): Promise<ApiResponse> =>
    callBlobAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .get()
    ),

  post: (url: string, body: unknown): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .post(body)
    ),
  put: (url: string, body: unknown): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .put(body)
    ),
  delete: (url: string): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .delete()
    ),
  postFormForToken: (
    url: string,
    params: any,
    controller?: AbortController
  ): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .headers({ 'content-type': 'application/x-www-form-urlencoded' })
        .post(params)
    ),
  postForm: (
    url: string,
    params: any,
    controller?: AbortController
  ): Promise<ApiResponse> =>
    callAPI(
      wretch(url)
        .auth(`Bearer ${getSessionStorageItem(CONSTANTS.REACT_TOKEN)}`)
        .formData(params)
        .post()
    ),
};
