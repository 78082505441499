export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';

// Action Type

export const LOADER = 'LOADER';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const IS_WAF_ADMIN = 'IS_WAF_ADMIN';

export const SNACK_BAR = 'SNACK_BAR';

export const BUTTON_LABEL = 'BUTTON_LABEL';

export const CUSTOM_BUTTON = 'CUSTOM_BUTTON';

export const CHART_TABLE_INFO = 'CHART_TABLE_INFO';

export const ATTACKS_BLOCKED_DATA = 'ATTACKS_BLOCKED_DATA';

export const HEAT_MAP_DATA = 'HEAT_MAP_DATA';

export const IS_LOADING_DATA = 'IS_LOADING_DATA';

export const LINE_CHART_LOADER = 'LINE_CHART_LOADER';

export const IS_LOADING_ATTACKS_BLOCKED = 'IS_LOADING_ATTACKS_BLOCKED';

// new dashboard

export const MENUS_LIST = 'MENUS_LIST';

export const DOMAIN_URLS_BY_CLIENT = 'DOMAIN_URLS_BY_CLIENT';

export const DOMAIN_OBJECT = 'DOMAIN_OBJECT';

export const STATS_DATA = 'STATS_DATA';

export const GEO_MAP_DATA = 'GEO_MAP_DATA';

export const ATTACKS_BY_SEVERITY = 'ATTACKS_BY_SEVERITY';
export const ATTACKS_BY_CATEGORY = 'ATTACKS_BY_CATEGORY';
export const ATTACK_TRENDLINE_DATA = 'ATTACK_TRENDLINE_DATA';
export const ATTACKS_INFORMATION_TABLE = 'ATTACKS_INFORMATION_TABLE';
export const REQUEST_STATS_INFORMATION = 'REQUEST_STATS_INFORMATION';

export const START_AND_END_DATE_TIME = 'START_AND_END_DATE_TIME';

export const DURATION_DATA = 'DURATION_DATA';

export const REFRESH_PAGE = 'REFRESH_PAGE';

export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const USER_PREFERENCES = 'USER_PREFERENCES';

// loading constants for each widget

export const HITS_AND_ATTACKS_LOADER = 'HITS_AND_ATTACKS_LOADER';
export const GEO_LOCATION_LOADER = 'GEO_LOCATION_LOADER';
export const ATTACKS_BY_SEVERITY_LOADER = 'ATTACKS_BY_SEVERITY_LOADER';
export const ATTACKS_BY_CATEGORY_LOADER = 'ATTACKS_BY_CATEGORY_LOADER';
export const ATTACK_TRENDLINE_LOADER = 'ATTACK_TRENDLINE_LOADER';
export const ATTACKS_INFORMATION_LOADER = 'ATTACKS_INFORMATION_LOADER';
export const USER_REQUEST_STATS_LOADER = 'USER_REQUEST_STATS_LOADER';

export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';

export const SET_COMBINED_RESPONSE = 'SET_COMBINED_RESPONSE';

export const SET_COMBINED_LOADER = 'SET_COMBINED_LOADER';
