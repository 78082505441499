import {
  ATTACKS_BY_CATEGORY,
  ATTACKS_BY_SEVERITY,
  ATTACKS_INFORMATION_TABLE,
  ATTACK_TRENDLINE_DATA,
  SET_COMBINED_RESPONSE,
  DURATION_DATA,
  GEO_MAP_DATA,
  MENUS_LIST,
  REQUEST_STATS_INFORMATION,
  START_AND_END_DATE_TIME,
  STATS_DATA,
} from '../../constants/actions';
import { initialStatsData } from '../../components/initial/InitialStatsData';
import { calculateRange } from '../../screen/utils/convertTime';
import { durations } from '../../components/initial/initialButtons';

export const initialUiState = {
  menusList: [],
  startAndEndDateTime: {
    ...calculateRange('12h'),
  },
  durationData: durations[0].value,

  combinedResponse: {
    cardsData: [...initialStatsData],
    geoMapData: {
      legit: [],
      attack: [],
    },
    severityDetails: {
      totalCount: 0,
      attacksBySeverity: [
        { value: 0, name: 'High', percentage: 0 },
        { value: 0, name: 'Medium', percentage: 0 },
        { value: 0, name: 'Low', percentage: 0 },
      ],
    },
    requestStats: {
      responseCodes: [],
      owaspTop10Mapping: [],
      userAgents: [],
      uriHits: [],
      requestMethods: [],
    },
    attacksByCategoryData: [],
  },

  statsData: [...initialStatsData],
  geoMapData: [],
  attacksBySeverity: [
    { value: 0, name: 'High', percentage: 0 },
    { value: 0, name: 'Medium', percentage: 0 },
    { value: 0, name: 'Low', percentage: 0 },
  ],
  attacksByCategory: [],
  attacksTrendlineData: [],
  attackInformationData: {
    data: [],
    total: 0,
  },
  requestStats: {
    responseCodes: [],
    owaspTop10Mapping: [],
    userAgents: [],
    uriHits: [],
    requestMethods: [],
  },
};

export const loadingUiReducer = (state = initialUiState, action) => {
  switch (action.type) {
    case DURATION_DATA:
      return {
        ...state,
        durationData: action.payload,
      };

    case START_AND_END_DATE_TIME:
      return {
        ...state,
        startAndEndDateTime: action.payload,
      };

    case STATS_DATA:
      return {
        ...state,
        statsData: action.payload,
      };

    case GEO_MAP_DATA:
      return {
        ...state,
        geoMapData: action.payload,
      };

    case ATTACKS_BY_SEVERITY:
      return {
        ...state,
        attacksBySeverity: action.payload,
      };
    case ATTACKS_BY_CATEGORY:
      return {
        ...state,
        attacksByCategory: action.payload,
      };

    case ATTACK_TRENDLINE_DATA:
      return {
        ...state,
        attacksTrendlineData: action.payload,
      };

    case ATTACKS_INFORMATION_TABLE:
      return {
        ...state,
        attackInformationData: action.payload,
      };

    case REQUEST_STATS_INFORMATION:
      return {
        ...state,
        requestStats: action.payload,
      };

    case MENUS_LIST:
      return {
        ...state,
        menusList: action.payload,
      };
    case SET_COMBINED_RESPONSE:
      return {
        ...state,
        combinedResponse: action.payload,
      };

    default:
      return state;
  }
};
