/* eslint-disable prettier/prettier */
import React from 'react';
import { INITIAL_THEME } from './theme';
import Navigator from './components/navigation/Navigator';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import RenderOnAuthenticated from './components/RenderOnAuthenticated';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import CONSTANTS from './constants/constants';
import { ThemeProvider } from '@mui/material';
import EulaWrapper from './components/eula/EulaWrapper';
import Wrapper from './components/layout/wrapper';
import Keycloak from 'keycloak-js';
import GlobalStylesComponent from './theme/GlobalStylesComponent';
import { setSessionStorageItem } from './encrypt';

const IS_WAF_ADMIN_ROLE = 'waf-admin'

const App = (props) => {
  const { config } = props;

  const keycloak: any = Keycloak({
    realm: `${config.realm}`,
    url: `${config.authURL}auth/`,
    clientId: `${config.clientId}`,
  });

  const setTokens = (): void => {
    const { token, refreshToken, idTokenParsed, tokenParsed } = keycloak;

    if (token && refreshToken && idTokenParsed) {
      const userType = tokenParsed?.clientRoles?.includes(IS_WAF_ADMIN_ROLE)
        ? CONSTANTS.WAF_ADMIN
        : CONSTANTS.USER;

      setSessionStorageItem(CONSTANTS.USER_EMAIL, idTokenParsed?.email);
      setSessionStorageItem(CONSTANTS.FIRST_NAME, idTokenParsed?.given_name);
      setSessionStorageItem(CONSTANTS.LAST_NAME, idTokenParsed?.family_name);
      setSessionStorageItem(CONSTANTS.USER_ID, idTokenParsed?.sub);
      setSessionStorageItem(CONSTANTS.REACT_TOKEN, token);
      setSessionStorageItem(CONSTANTS.USER_TYPE, userType);
      setSessionStorageItem(CONSTANTS.AWGMENT_USER_ID, tokenParsed?.userId);

      setSessionStorageItem(CONSTANTS.KC_REALM_NAME, config.realm);
      setSessionStorageItem(CONSTANTS.GATEWAY_URL, config.gatewayURL);
      setSessionStorageItem(CONSTANTS.KC_AUTH_URL, config.authURL);
      setSessionStorageItem(CONSTANTS.KC_CLIENT_ID, config.clientId);
      setSessionStorageItem(CONSTANTS.AWGMENT_API, config.awgmentApi);
      setSessionStorageItem(CONSTANTS.EULA_DOC_ID, config.eulaDocId);
      setSessionStorageItem(CONSTANTS.DMS_DOC_ID, config.dmsDocId);
    }
  };

  const refreshAccessToken = (): void => {
    keycloak
      .updateToken(50)
      .success((refreshed: boolean) => {
        if (refreshed) {
          setTokens();
        }
      })
      .error(() => {
        sessionStorage.clear();
        keycloak.logout();
      });
  };

  const handleEvent = (event: string): void => {
    if (event === CONSTANTS.ON_AUTH_SUCCESS) {
      if (window.location.href.indexOf(CONSTANTS.SIGN_UP) > -1) {
        window.location.href = CONSTANTS.NAVIGATE_TO_DASHBOARD;
      }
      setTokens();
    }

    if (event === CONSTANTS.ON_TOKEN_EXPIRED) {
      refreshAccessToken();
    }

    if (event === CONSTANTS.ON_AUTH_LOGOUT) {
      sessionStorage.clear();
    }
  };

  const getAppChildren = () => (
    <ThemeProvider theme={INITIAL_THEME}>
      <Provider store={store}>
        <GlobalStylesComponent />

        <EulaWrapper>
          {({ showContent }) => (
            <Wrapper showContent={showContent}>
              <GlobalStylesComponent />
              <Navigator />
            </Wrapper>
          )}
        </EulaWrapper>
      </Provider>
    </ThemeProvider>
  );

  return (
    <div className="app-wrapper">
      <ReactKeycloakProvider
        initOptions={{
          onLoad: 'login-required',
          checkLoginIframe: false,
        }}
        authClient={keycloak}
        onEvent={handleEvent}
      >
        <RenderOnAuthenticated>{getAppChildren()}</RenderOnAuthenticated>
      </ReactKeycloakProvider>
    </div>
  );
};

export default App;
