import React, { useState, useEffect } from 'react';
import EulaModal from './EulaModal';
import CONSTANTS from '../../constants/constants';
import {
  EULA_CHECK_ENDPOINT,
  EULA_SET_ENDPOINT,
} from '../../constants/endpoints';
import { request } from '../../services/request';
import { useLogout } from '../../screen/utils/logout';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../redux/notification/notificationActions';
import { useNavigate } from 'react-router-dom';
import NoDomainsOnboarded from '../../screen/NoDomainsOnboarded';
import '../../styles/EulaStyles.css';
import { getSessionStorageItem, setSessionStorageItem } from '../../encrypt';

interface EulaWrapperProps {
  children: (props: { showContent: boolean }) => React.ReactNode;
}

const EulaWrapper: React.FC<EulaWrapperProps> = ({ children }) => {
  const [showEula, setShowEula] = useState(false);
  const [isNoDomains, setIsNoDomains] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const userId = getSessionStorageItem(CONSTANTS.AWGMENT_USER_ID);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = useLogout();

  useEffect(() => {
    const checkEulaStatus = async () => {
      try {
        const storedEulaStatus = getSessionStorageItem(
          CONSTANTS.EULA_ACCEPTANCE
        );

        if (storedEulaStatus === null) {
          const eulaCheckApiUrl = `${getSessionStorageItem(
            CONSTANTS.GATEWAY_URL
          )}${EULA_CHECK_ENDPOINT}`;
          const response: any = await request.get(eulaCheckApiUrl);
          if (response?.success) {
            const isAcceptedEula = response?.data?.is_accepted_eula;
            if (isAcceptedEula) {
              setShowEula(isAcceptedEula);
              setShowLoader(false);
              setSessionStorageItem(
                CONSTANTS.EULA_ACCEPTANCE,
                isAcceptedEula.toString()
              );
            }
            setShowEula(!isAcceptedEula);
            setShowLoader(true);
            setTimeout(() => {
              setShowLoader(false);
            }, 0.0001);
          } else {
            setShowLoader(true);
            setTimeout(() => {
              setShowLoader(false);
            }, 0.0001);
            setIsNoDomains(!isNoDomains);
          }
        } else {

          if (JSON.parse(storedEulaStatus)) {
            setShowLoader(false);

            setShowEula(!JSON.parse(storedEulaStatus));
          } else {
            setShowLoader(true);
            setTimeout(() => {
              setShowLoader(false);
            }, 0.0001);

            setShowEula(JSON.parse(storedEulaStatus));
          }
        }
      } catch (error) {
        handleLogout();
      }
    };

    if (getSessionStorageItem(CONSTANTS.REACT_TOKEN)) {
      checkEulaStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptEula = async () => {
    try {
      const EULA_ACCEPT_URL = `${getSessionStorageItem(
        CONSTANTS.GATEWAY_URL
      )}${EULA_SET_ENDPOINT}${userId}`;

      const data = {
        is_accepted_eula: true,
      };
      const response: any = await request.put(EULA_ACCEPT_URL, data);

      if (response?.success) {
        setShowEula(false);
        setSessionStorageItem(CONSTANTS.EULA_ACCEPTANCE, CONSTANTS.TRUE_TEXT);

        dispatch(
          pushNotification({
            isOpen: true,
            message: CONSTANTS.UPDATED_EULA_ACCEPTANCE_MSG,
            type: CONSTANTS.SUCCESS,
          })
        );
      } else if (
        response?.message?.includes(CONSTANTS.MAPPING_FOR_USER_ID) &&
        response?.message?.includes(CONSTANTS.NOT_FOUND)
      ) {
        setShowEula(false);
        setIsNoDomains(!isNoDomains);
        navigate(`${CONSTANTS.NAVIGATE_TO_DASHBOARD}no-domains-onboarded`);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: CONSTANTS.FAILED_EULA_MSG,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } catch (error) {
      handleLogout();
    }
  };

  const handleRejectEula = useLogout();

  const userType =
    getSessionStorageItem(CONSTANTS.USER_TYPE) === CONSTANTS.WAF_ADMIN;

  const showContent = !showEula && !showLoader;

  return (
    <div>
      {isNoDomains && !userType ? (
        <NoDomainsOnboarded />
      ) : (
        <>
          {showLoader && (
            <div className="eula_loader-container">
              <div data-testid="eula-loader" className="eula_loader"></div>
            </div>
          )}
          {showEula && !showLoader && (
            <EulaModal
              isOpen={showEula}
              onAccept={handleAcceptEula}
              onReject={handleRejectEula}
            />
          )}
          {children({ showContent })}
        </>
      )}
    </div>
  );
};

export default EulaWrapper;
