/* eslint-disable prettier/prettier */
import moment from 'moment';
import * as Yup from 'yup';
import ReusableForm from '../../../components/common/customForm';
import { addOrEditOrDeleteClient } from '../../../services/clientServices';
import { pushNotification } from '../../../redux/notification/notificationActions';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';

const initialValues = {
  client_name: '',
  business_owner: '',
  business_owner_email: '',
  business_owner_phone: '',
  technical_owner: '',
  technical_owner_email: '',
  technical_owner_phone: '',
  onboarding_date: moment().format('DD/MM/YYYY'),
  address: '',
  gstin: '',
  pan: '',
}

export const clientValidationSchema = Yup.object().shape({
    client_name: Yup.string().required('Client Name is Requried').trim(),
    business_owner: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])[\w ]+$/,
        'Business Owner must contain only letters'
      )
      .required('Business owner\'s name is Requried'),
    business_owner_email: Yup.string()
      .email('Please Enter a Valid Email Id')
      .required('Business owner\'s email is Requried'),
    business_owner_phone: Yup.string()
      .notRequired()
      .matches(/^\d{0,10}$/, 'Phone number must be at most 10 digits')
      .max(10, 'Maximum length of phone number is 10 digits'),
    technical_owner: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])[\w ]+$/,
        'Technical Owner must contain only letters'
      )
      .required('Technical owner\'s name is Requried'),
    technical_owner_email: Yup.string()
      .email('Please Enter a Valid Email Id')
      .required('Technical owner\'s email is Requried'),
    technical_owner_phone: Yup.string()
      .notRequired()
      .matches(/^\d{0,10}$/, 'Phone number must be at most 10 digits')
      .max(10, 'Maximum length of phone number is 10 digits'),
    address: Yup.string(),
    gstin: Yup.string(),
    pan: Yup.string(),
})

export const formPayload: any = (values: any) => ({
  client_name: values?.client_name,
  business_owner: values?.business_owner,
  business_owner_email: values?.business_owner_email,
  business_owner_phone: values?.business_owner_phone,
  technical_owner: values?.technical_owner,
  technical_owner_email: values?.technical_owner_email,
  technical_owner_phone: values?.technical_owner_phone,
  address: values?.address,
  gstin: values?.gstin,
  pan: values?.pan,
  ...(values?.onboarding_date && { onboarding_date: values?.onboarding_date }), 
});


export const clientFields = [
  {
    name: 'client_name',
    label: 'Client Name',
    type: 'text',
    placeholder: 'Client Name',
    required: true
  },
  {
    name: 'business_owner',
    label: 'Business Owner',
    type: 'text',
    placeholder: 'Business Owner',
    required: true
  },
  {
    name: 'business_owner_email',
    label: 'Business Owner Email',
    type: 'text',
    placeholder: 'Business Owner Email',
  },
  {
    name: 'business_owner_phone',
    label: 'Business Owner Phone',
    type: 'text',
    placeholder: 'Business Owner Phone',
  },
  {
    name: 'technical_owner',
    label: 'Technical Owner',
    type: 'text',
    placeholder: 'Technical Owner',
    required: true
  },
  {
    name: 'technical_owner_email',
    label: 'Technical Owner Email',
    type: 'text',
    placeholder: 'Technical Owner Email', 
    required: true
  },
  {
    name: 'technical_owner_phone',
    label: 'Technical Owner Phone',
    type: 'text',
    placeholder: 'Technical Owner Phone',
  },
  {
    name: 'address',
    label: 'Address',
    type: 'text',
    placeholder: 'Address',
    required: true
  },
  {
    name: 'gstin',  
    label: 'GSTIN',
    type: 'text',
    placeholder: 'GSTIN', 
  },
  {
    name: 'pan',
    label: 'PAN',
    type: 'text',
    placeholder: 'PAN',
  }
  
];

interface CreateClientProps {
  id: string;
  show: boolean;
  setShow: any;
  handleGetAllClients: any;
}


const CreateClient: React.FC<CreateClientProps> = ({id,show,setShow, handleGetAllClients}) => {

  const dispatch = useDispatch();

  const handlePostApiRequest = async (data) => {
    const response = await addOrEditOrDeleteClient('add-client', null, data);

    if (response?.success) {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      handleGetAllClients();
    } else {
      setShow(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const postFormValues = (values: any) => {
    const postData = formPayload(values);
    handlePostApiRequest(postData);
  };
  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(false);

  };



  return (
    <ReusableForm
      id={id}
      open={show}
      initialValues={initialValues}
      validationSchema={clientValidationSchema}
      fields={clientFields}
      title="Register Client"
      onClose={() => setShow(false)}
      buttonLabel="Add Client"
      onSubmit={handleSubmit}


    />
  );
};

export default CreateClient;
