/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Popover, Typography, TextField, Divider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  differenceInCalendarDays,
  differenceInHours,
  addDays,
  isBefore,
  isAfter,
} from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { request } from '../../../services/request';
import { getDownloadPDFApiEndpoint } from '../../../constants/endpoints';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import { pushNotification } from '../../../redux/notification/notificationActions';
import FileSaver from 'file-saver';
import { setIsDownloadingPdf } from '../../../redux/loader/loadingActions';
import './customDate.css';
import { convertToISTFormat } from '../../../screen/utils/convertTime';
import CustomButton from '../customButton';
import { getSessionStorageItem } from '../../../encrypt';
const useStyles = makeStyles((theme) => ({
  popover: {
    padding: '1rem',
    width: '30%',
    '@media (min-width:2560px)': {
      width: '24%',
    },
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  datePickerLabel: {
    color: '#0046FF !important',
    marginBottom: '0.5rem',
    fontSize: '0.9rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize: '1.6rem !important',
    },
  },

  datePicker: {
    '& .MuiInputBase-root': {
      width: '80%',
      border: 'none',
      '&:hover': {
        border: 'none',
      },
      '&.Mui-focused': {
        border: 'none',
        boxShadow: 'none',
      },
      '&::before': {
        borderBottom: 'none',
      },
      '&::after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
    '& .MuiInputBase-input': {
      padding: '0',
      color: 'gray',
      fontSize: '0.9rem !important',
      '@media (min-width:2560px)': {
        fontSize: '1.2rem !important',
      },
      '@media (min-width:3860px)': {
        fontSize: '1.6rem !important',
      },
    },

    '& .MuiInputBase-input::placeholder': {
      textTransform: 'uppercase',
    },
  },
  divider: {
    margin: '0 -1rem 0.1rem -1rem !important',
    backgroundColor: '#0046FF',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
 
  selectedDay: {
    backgroundColor: '#0046FF !important',
    color: '#fff !important',
  },
}));

export const datePickerStyles = {
  '& .PrivatePickersYear-yearButton': {
    fontSize: '0.9rem !important',
    color: '#0046FF !important',
    '@media (min-width:1950px)': {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize: '1.6rem !important',
    },
  },
  '& .PrivatePickersYear-yearButton.Mui-selected': {
    fontSize: '0.9rem !important',
    color: '#FFFFFF !important',
    backgroundColor: '#0046FF !important',
    '@media (min-width:1950px)': {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize: '1.6rem !important',
    },
  },
  '& .PrivatePickersMonth-root': {
    fontSize: '0.9rem !important',
    color: '#0046FF !important',
    '@media (min-width:1950px)': {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize: '1.6rem !important',
    },
  },
  '& .PrivatePickersMonth-root.Mui-selected': {
    fontSize: '0.9rem !important',
    color: '#FFFFFF !important',
    backgroundColor: '#0046FF !important',
    '@media (min-width:1950px)': {
      fontSize: '1.2rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '1.4rem !important',
    },
    '@media (min-width:3860px)': {
      fontSize: '1.6rem !important',
    },
  },
 
  '& .MuiPickersDay-root.Mui-selected': {
    backgroundColor: '#0046FF !important',
    color: '#FFFFFF !important',
  },
};

const CustomDate = ({ id, anchorEl, setAnchorEl, domainId }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const customDateId = open ? id : undefined;

  const dispatch = useDispatch();

  const handleOkClick = async () => {
    if (startDate && endDate) {
      setAnchorEl(null);

      const endDateTime = new Date(endDate);

      endDateTime?.setHours(23);
      endDateTime?.setMinutes(59);
      endDateTime?.setSeconds(59);

      dispatch(setIsDownloadingPdf(true));
      const daysDifference = differenceInCalendarDays(
        new Date(endDateTime),
        new Date(startDate)
      );
      const hoursDifference = differenceInHours(
        new Date(endDateTime),
        new Date(startDate)
      );

    if (daysDifference <= 0 && hoursDifference <= 0) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: CONSTANTS.START_DATE_PRIOR_TO_END_DATE,
            type: CONSTANTS.ERROR,
          })
        );
      } else if (hoursDifference > 1 && daysDifference <= 7) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: CONSTANTS.PLEASE_WAIT_MESSAGE,
            type: CONSTANTS.SUCCESS,
          })
        );

        const formattedStartDate = convertToISTFormat(startDate);
        const formattedEndDate = convertToISTFormat(endDateTime);

        const startAndEndDateTime = {
          from: formattedStartDate,
          to: formattedEndDate,
        };

        const DOWNLOAD_PDF_ENDPOINT = getDownloadPDFApiEndpoint(
          domainId,
          startAndEndDateTime
        );

        try {
          const res: any = await request.getBlob(
            `${getSessionStorageItem(
              CONSTANTS.GATEWAY_URL
            )}${DOWNLOAD_PDF_ENDPOINT}`
          );

          if (!res?.success) {
            dispatch(
              pushNotification({
                isOpen: true,
                message:CONSTANTS.PDF_DOWNLOAD_FAILURE_MSG,
                type: CONSTANTS.ERROR,
              })
            );
          } else if (res?.data) {
            const blob = new Blob([res?.data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, CONSTANTS.DOWNLOADED_LOGS_FILE_NAME);

            dispatch(
              pushNotification({
                isOpen: true,
                message: CONSTANTS.PDF_DOWNLOAD_SUCCESS_MSG,
                type: CONSTANTS.SUCCESS,
              })
            );
          }
        } catch (error:any) {
          dispatch(
            pushNotification({
              isOpen: true,
              message:CONSTANTS.PDF_DOWNLOAD_FAILURE_MSG || error?.message,
              type: CONSTANTS.ERROR,
            })
          );
        }
      }else{
          dispatch(
            pushNotification({
              isOpen: true,
              message: CONSTANTS.DOWNLOAD_FOR_LESS_OR_EQUAL_TO_7_DAYS,
              type: CONSTANTS.ERROR,
            })
          );
        
      }
      dispatch(setIsDownloadingPdf(false));
    }
  };

  const disableEndDate = (date) => {
    if (!startDate) return false;
    const maxEndDate = addDays(new Date(startDate), 6);
    return isBefore(date, new Date(startDate)) || isAfter(date, maxEndDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Popover
        id={customDateId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <div
          id={`${customDateId}-container`}
          className={classes.datePickerContainer}
        >
          <div id={`${customDateId}-startDateContainer`}>
            <Typography
              id={`${customDateId}-startDateLabel`}
              variant="body2"
              className={classes.datePickerLabel}
            >
              Start Date
            </Typography>
            
            <DatePicker
              className="custom-date-picker"
              value={startDate}
              views={['year', 'month', 'day']}
              PopperProps={{
                sx: datePickerStyles,
              }}
              onChange={(newValue: any) => {
                setStartDate(newValue);
                setEndDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  id={`${customDateId}-startDatePicker`}
                  {...params}
                  variant="standard"
                  placeholder="DD/MM/YYYY"
                  className={classes.datePicker}
                />
              )}
              inputFormat="dd/MM/yyyy"
              disableFuture
            />
          </div>

          <div id={`${customDateId}-endDateContainer`}>
            <Typography
              id={`${customDateId}-endDateLabel`}
              variant="body2"
              className={classes.datePickerLabel}
            >
              End Date
            </Typography>
            <DatePicker
              className="custom-date-picker"
              views={['year', 'month', 'day']}
              PopperProps={{
                sx: datePickerStyles,
              }}
              value={endDate}
              onChange={(newValue: any) => {
                newValue?.setHours('23');
                newValue?.setMinutes('59');
                newValue?.setSeconds('59');
                setEndDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  id={`${customDateId}-endDatePicker`}
                  {...params}
                  variant="standard"
                  placeholder="DD/MM/YYYY"
                  className={classes.datePicker}
                />
              )}
              disableFuture
              inputFormat="dd/MM/yyyy"
              shouldDisableDate={disableEndDate}
            />
          </div>
        </div>
        <Divider id="divider" className={classes.divider} />
        <div className={classes.actions}>

          <CustomButton
            backgroundColor='transparent'
            textColor='#909097'
            label={CONSTANTS.CANCEL_BTN_TEXT}
            onClick={() => setAnchorEl(null)}
            id={'pdfCancancelBtn'}
          />
          <CustomButton
            backgroundColor="transparent"
            textColor="#0046FF"
            id={'pdfOkBtn'}
            label={CONSTANTS.OK_BTN_TEXT}
            onClick={handleOkClick}
          />
        </div>
      </Popover>
    </LocalizationProvider>
  );
};

export default CustomDate;
