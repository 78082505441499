/* eslint-disable prettier/prettier */
import {
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CONSTANTS, { DATE_SLASH_FORMAT, URL_PATTERN } from '../../../constants/constants';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as Yup from 'yup';
import { pushNotification } from '../../../redux/notification/notificationActions';
import moment from 'moment';
import { addOrEditOrDeleteClient } from '../../../services/clientServices';
import ReusableForm from '../../../components/common/customForm';
import { makeStyles } from '@material-ui/core';

interface AddDomainToClientProps {
  clientId: string;
  handleGetPaginatedClients?: any;
  initialValues?: any;
  isEditing?: boolean;
  onClose?: any;
  anchorEl?: HTMLElement | null;
  handleAddDomainBtnClick?: any;
}

const domainValidationSchema = Yup.object().shape({
    domain_name: Yup.string()
      .required('Domain name is required'),
    domain_url: Yup.string()
      .matches(URL_PATTERN, 'Invalid URL')
      .required('Domain URL is required'),
    db_name: Yup.string().required('Database name is required'),
    ip_address: Yup.string().required('IP address is required'),
    current_mode: Yup.string().required('Current mode is required'),
    hosting_service_name: Yup.string().required(
      'Hosting service name is required'
    ),
    dns_mapped: Yup.boolean().required('DNS mapped is required'),
    mapped_on: Yup.string().required('Mapped on date is required'),
});
const domainFields = [
  { name: 'domain_name', label: 'Domain Name', type: 'text', placeholder: 'Enter domain name',required: true },
  { name: 'domain_url', label: 'Domain URL', type: 'text', placeholder: 'Enter domain URL',required: true },
  { name: 'db_name', label: 'Database Name', type: 'text', placeholder: 'Enter database name',required: true },
  { name: 'ip_address', label: 'IP Address', type: 'text', placeholder: 'Enter IP address',required: true },
  { name: 'current_mode', label: 'Current Mode', type: 'text', placeholder: 'Transparent Mode',disabled: true },
  { name: 'hosting_service_name', label: 'Hosting Service Name', type: 'text', placeholder: 'Enter hosting service' },
  { name: 'dns_mapped', label: 'DNS Mapped', type: 'checkbox' },
  { name: 'mapped_on', label: 'Mapped On', type: 'text', placeholder: 'Mapped on date' },
];

const useStyles = makeStyles((theme) => ({
  iconButton: {
    fontSize: '1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '3.2rem',
    },
  },
}));


const AddDomainToClient: React.FC<AddDomainToClientProps> = ({
  clientId,
  handleGetPaginatedClients,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddCircleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const dispatch = useDispatch();
  const classes = useStyles();

  const initialValues = {
    client_id: clientId,
    domain_name: '',
    domain_url: '',
    db_name: '',
    ip_address: '',
    current_mode: 'Off',
    hosting_service_name: CONSTANTS.DEFAULT_HOSTING_SERVICE,
    dns_mapped: true,
    mapped_on: moment().format(DATE_SLASH_FORMAT),
  };

  const formPayload = (values: any) => ({
    domain_name: values?.domain_name,
    domain_url: values?.domain_url,
    db_name: values?.db_name,
    ip_address: values?.ip_address,
    current_mode: values?.current_mode,
    hosting_service_name: values?.hosting_service_name,
    dns_mapped: values?.dns_mapped,
    mapped_on: values?.mapped_on,
    client_id: values?.client_id,
  });

  const postFormValues = (values: any) => {
    const postData = formPayload(values);
    handlePostApiRequest(postData);
  };

  const handlePostApiRequest = async (data) => {
    const response: any = await addOrEditOrDeleteClient(
      'add-domain',
      null,
      data
    );

    if (response?.success) {
      handleClosePopover();
      handleGetPaginatedClients();
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      handleClosePopover();

      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(false);

  };

  return (
    <>
      <IconButton onClick={handleAddCircleButtonClick}>
        <AddCircleOutlineIcon className={classes.iconButton} />

      </IconButton>
      <ReusableForm
        id="addDomainForm"
        open={isPopoverOpen}
        onClose={handleClosePopover}
        initialValues={initialValues}
        validationSchema={domainValidationSchema}
        onSubmit={handleSubmit}
        fields={domainFields}
        title="Add Domain to Client"
        buttonLabel={CONSTANTS.ADD_URL_BTN_TEXT}
      />
    </>
  );
};

export default AddDomainToClient;
