/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Chip, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import CONSTANTS from '../../../constants/constants';
import { deleteUrlFromClient } from '../../../services/clientServices';
import { pushNotification } from '../../../redux/notification/notificationActions';
import EditDomain from './EditDomain';
import DeleteConfirmationDialog from '../../../components/common/customDeleteBox/DeleteConfirmationDialog';
import { makeStyles } from '@material-ui/core';

interface Props {
  domain: string;
  domainId: string;
  clientId?: any;
  domainInfo?: any;
  handleGetPaginatedClients?: () => void;
}

const useStyles = makeStyles((theme) => ({
  chipContainer:{
    padding:'0.5rem'
  },
  chip: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '2rem !important',

    padding: theme.spacing(1),
    backgroundColor: 'lightblue',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1.5), 
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2), 
    },
    [theme.breakpoints.up(2560)]: {
      padding: '1.5rem 2.5rem !important',
    },
    [theme.breakpoints.up(3840)]: {
      padding: '1.8rem 2.8rem !important',
    },
    '& .MuiChip-label': {
      fontSize: '0.7rem', 
      [theme.breakpoints.up('lg')]: {
        fontSize: '0.9rem', 
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.1rem', 
      },
      [theme.breakpoints.up(2560)]: {
        fontSize: '1.5rem !important',
      },
      [theme.breakpoints.up(3840)]: {
        fontSize: '1.8rem !important',
      },
    },
  },
  editIcon: {
    color: 'blue',
    fontSize: '1.2rem',
    [theme.breakpoints.up(2560)]: {
      fontSize: '1.5rem !important',
    },
    [theme.breakpoints.up(3840)]: {
      fontSize: '1.8rem !important',
    },
  },
  deleteIcon: {
    cursor: 'pointer',
    color: 'gray',
    fontSize: '1.4rem',
    [theme.breakpoints.up(2560)]: {
      fontSize: '1.7rem !important',
    },
    [theme.breakpoints.up(3840)]: {
      fontSize: '2.2rem !important',
    },
  },
}));

const EditableChip: React.FC<Props> = ({
  domain,
  domainId,
  clientId,
  domainInfo,
  handleGetPaginatedClients,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDomainDelete, setIsDomainDelete] = useState<boolean>(false);
  const dispatch = useDispatch();

  const classes = useStyles()
  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsEditing(true);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    setAnchorEl(null);
  };


  const handleDeleteClick = async () => {
    const response: any = await deleteUrlFromClient(domainId);

    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      if (handleGetPaginatedClients) {
        handleGetPaginatedClients();
      }
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response?.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  return (
    <div className={classes.chipContainer}>
      <Chip
        className={classes.chip}
        label={domain}
        variant="outlined"
        onDelete={handleDeleteClick}
        deleteIcon={
          <>
            <IconButton
              data-testid="editDomain"
              onClick={handleEditClick}
              size="small"
              title="Edit Domain"
            >
              <Edit className={classes.editIcon}/>
            </IconButton>
            <IconButton
              data-testid="deleteDomain"
              onClick={() => setIsDomainDelete(true)}
              title="Delete Domain" 
            >
              <Delete className={classes.deleteIcon}/>
            </IconButton>
          </>
        }
      />
      {isDomainDelete && (
        <DeleteConfirmationDialog
          title={CONSTANTS.IS_DELETE_DOMAIN}
          description={CONSTANTS.DELETE_DOMAIN_CONFIRMATION_MSG}
          open={isDomainDelete}
          onClose={() => setIsDomainDelete(false)}
          onConfirm={handleDeleteClick}
        />
      )}
      {isEditing && (
        <EditDomain
          clientId={clientId}
          domainInfo={domainInfo}
          handleGetPaginatedClients={handleGetPaginatedClients}
          isEditing={isEditing}
          onClose={handleCloseEdit}
        />
      )}
    </div>
  );
};

export default EditableChip;
