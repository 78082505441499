/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Collapse } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import {
  addOrEditOrDeleteClient,
  getAllPaginatedClients,
} from '../../../services/clientServices';
import { pushNotification } from '../../../redux/notification/notificationActions';
import CONSTANTS from '../../../constants/constants';
import EditableChip from './EditableChip';
import AddDomainToClient from './AddDomainToClient';
import ReusableTable from '../../../components/common/customTable/ReusableTable';
import CreateClient from './CreateClient';
import EditClient from './EditClient';
import DeleteConfirmationDialog from '../../../components/common/customDeleteBox/DeleteConfirmationDialog';
import { addOrEditOrDeleteUser, getAllUsers } from '../../../services/userService';

const useStyles = makeStyles((theme) => ({
  domainsBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    padding: '1rem 0rem',
  },
  deleteIcon: {
    color: '#FB5607 !important',
  },
  editIcon: {
    color: '#0046FF !important',
  },
  expandMoreIcon: {
    color: '#0046FF !important',
    transition: 'transform 0.3s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const AllClientsList: React.FC = () => {
  const [clientsList, setClientsList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateClient, setIsCreateClient] = useState(false);
  const [isEditClient, setIsEditClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [deleteClient, setDeleteClient] = useState({
    clientId: null,
    clientName: '',
  });
  const [expandedRow, setExpandedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    handleGetPaginatedClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchTerm]);

  const handleGetPaginatedClients = async () => {
    setIsLoading(true);
    const res = await getAllPaginatedClients(
      page + 1,
      rowsPerPage,
      'client_name',
      searchTerm
    );
    setClientsList(res?.data?.clients || []);
    setTotalRecords(res?.data?.total_records || 0);
    setIsLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleSearch = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleEditClient = (client) => {
    setSelectedClient(client);
    setIsEditClient(true);
  };

  const handleDeleteClient = async () => {
    if (!deleteClient?.clientId) return;

    
    try {
      const usersResponse = await getAllUsers();
      if (!usersResponse?.data) {
        return
      }
  
      // deleting all users associated with the client being deleted
      const usersToUpdate = usersResponse?.data?.filter(
        user => user?.userData?.clientName === deleteClient?.clientName
      );
  
      const updatePromises = usersToUpdate.map(user => {
        const updatedUserDetails = { ...user.userData, clientName: '' };
        return addOrEditOrDeleteUser('update', user?.userId, updatedUserDetails);
      });
  
      await Promise.all(updatePromises);
  
      const deleteResponse = await addOrEditOrDeleteClient(
        'delete',
        deleteClient?.clientId,
        null
      );
  
      if (deleteResponse?.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: deleteResponse?.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        handleGetPaginatedClients();
      } else {
        throw new Error(CONSTANTS.DELETE_CLIENT_FAILURE_MSG);
      }
    } catch (error) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: CONSTANTS.DELETE_CLIENT_FAILURE_MSG,
          type: CONSTANTS.ERROR,
        })
      );
    } finally {
      setDeleteClient({
        clientId: null,
        clientName: '',
      });
    }
  };

  const handleExpandRow = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const columns = [
    { id: 'client_name', label: 'Client Name', minWidth: 100 },
    { id: 'business_owner', label: 'Business Owner', minWidth: 100 },
    { id: 'business_owner_email', label: 'B.O Email', minWidth: 120 },
    { id: 'business_owner_phone', label: 'B.O Phone', minWidth: 120 },
    { id: 'technical_owner', label: 'Technical Owner', minWidth: 100 },
    { id: 'technical_owner_email', label: 'T.O Email', minWidth: 120 },
    { id: 'technical_owner_phone', label: 'T.O Phone', minWidth: 120 },
    { id: 'actions', label: 'Actions', minWidth: 100, align: 'center' },
  ];

  const renderCustomCell = (column, row) => {
    if (column?.id === 'actions') {
      return (
        <Box className={classes.actions}>
          <IconButton
            className={classes.editIcon}
            title="Edit Client"
            onClick={() => handleEditClient(row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            className={classes.deleteIcon}
            title="Delete Client"
            onClick={() =>
              setDeleteClient({
                clientId: row?.client_id,
                clientName: row?.client_name,
              })
            }
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            title="View Domains"
            onClick={() => handleExpandRow(row?.client_id)}
          >
            <ExpandMoreIcon
              className={`${classes.expandMoreIcon} ${
                expandedRow === row?.client_id ? classes.expandOpen : ''
              }`}
            />
          </IconButton>
        </Box>
      );
    }
    return row[column?.id] || '-';
  };

  const renderExpandedRow = (row) => {
    return (
      <Collapse
        in={expandedRow === row?.client_id}
        timeout="auto"
        unmountOnExit
      >
        <Box className={classes.domainsBox}>
          {row?.domains_data?.length > 0 ? (
            row?.domains_data?.map((item) => (
              <EditableChip
                key={item?.domain_id}
                domainInfo={item}
                domain={item?.domain_name}
                domainId={item?.domain_id}
                clientId={row?.client_id}
                handleGetPaginatedClients={() => handleGetPaginatedClients()}
              />
            ))
          ) : (
            <Typography>No domains added</Typography>
          )}

          <AddDomainToClient
            clientId={row?.client_id}
            handleGetPaginatedClients={() => handleGetPaginatedClients()}
          />
        </Box>
      </Collapse>
    );
  };

  return (
    <>
      <ReusableTable
        title="Clients"
        columns={columns}
        data={clientsList}
        totalCount={totalRecords}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onSearch={handleSearch}
        searchPlaceholder="Search clients..."
        onAddItem={() => setIsCreateClient(true)}
        buttonLabel="Add Client"
        renderExpandedRow={renderExpandedRow}
        renderCustomCell={renderCustomCell}
        isLoading={isLoading}
        id={'clientsListTable'}
      />

      <CreateClient
        id="createClient"
        show={isCreateClient}
        setShow={setIsCreateClient}
        handleGetAllClients={handleGetPaginatedClients}
      />

      <EditClient
        id="editClientForm"
        show={isEditClient}
        setShow={setIsEditClient}
        selectedClient={selectedClient}
        handleGetAllClients={handleGetPaginatedClients}
      />

      <DeleteConfirmationDialog
        open={Boolean(deleteClient?.clientId)}
        onClose={() =>
          setDeleteClient({
            clientId: null,
            clientName: '',
          })
        }
        onConfirm={handleDeleteClient}
      />
    </>
  );
};

export default AllClientsList;
