/* eslint-disable prettier/prettier */
import { Autocomplete, TextField, Box, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAllPaginatedClients } from '../../../services/clientServices';
import CustomButton from '../../../components/common/customButton';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  autocomplete: {
    width: '96%',
  },
  buttonContainer: {
    width: '97%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hiddenClearIcon: {
    display: 'none',
  },
}));

interface AutoCompleteClientsProps {
  handleChangeClient: any;
  initialClientName?: string;
}

const AutoCompleteClients: React.FC<AutoCompleteClientsProps> = ({
  handleChangeClient,
  initialClientName = '',
}) => {
  const [clientsList, setClientsList] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [clientName, setClientName] = useState<string>(initialClientName);

  useEffect(() => {
    const handleGetAllClients = async () => {
      const response = await getAllPaginatedClients(
        1,
        10,
        'client_name',
        clientName
      );
      setClientsList(response?.data?.clients || []);
    };
    handleGetAllClients();
  }, [clientName]);

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedClient(newValue);
    setClientName(newValue ? newValue?.client_name : '');
  };

  const handleMapClient = () => {
    if (selectedClient) {
      handleChangeClient(
        selectedClient?.client_name,
        selectedClient?.client_id
      );
    }
  };

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Autocomplete
        value={selectedClient}
        onKeyUp={(e: any) => {
          setClientName(e.target.value);
        }}
        onChange={handleAutocompleteChange}
        options={clientsList}
        getOptionLabel={(option) => option?.client_name || ''}
        renderInput={(params) => (
          <TextField
            className={classes.autocomplete}
            {...params}
            label="Select Client Name"
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <InputAdornment position="end">
                  <span className={classes.hiddenClearIcon}>
                    {params.InputProps.endAdornment}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Box className={classes.buttonContainer}>
        <CustomButton
          id="mapClientBtn"
          label="Map Client"
          onClick={handleMapClient}
          disabled={!selectedClient}
        />
      </Box>
    </Box>
  );
};

export default AutoCompleteClients;
