import {
  TEMPLATE_UPLOAD_ENDPOINT,
  TEMPLATE_DOWNLOAD_ENDPOINNT,
  EULA_TEXT_DOWNLOAD_ENDPOINT,
  USER_PREFERENCE,
  PROFILE_PICTURE_ENDPOINT,
} from '../constants/endpoints';
import { ResponseProps, request } from './request';
import { MetaInfo } from '../types/types';
import CONSTANTS from '../constants/constants';
import { getSessionStorageItem } from '../encrypt';
import { REQUEST_METHODS } from '../constants/common';

export const uploadFileApi = async (
  fileName: string,
  file: File,
  documentPath: string,
  meta?: MetaInfo
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${fileName}`);
  const params = {
    documentName: fileName,
    file: fileOfBlob,
    documentPath: documentPath,
    documentDescription: '',
    metaInfo: JSON.stringify(meta),
  };

  const r: ResponseProps = (await request.postForm(
    `${TEMPLATE_UPLOAD_ENDPOINT}`,
    params
  )) as ResponseProps;
  if (r && r.success) {
    return { success: true, data: r.data, message: r.message };
  }
  return { success: false, data: {}, message: '' };
};

export const downloadTemplateApi = async (
  id: string
): Promise<{ success: boolean; message?: string; blobFile?: any }> => {
  const response = (await request.getBlob(
    `${TEMPLATE_DOWNLOAD_ENDPOINNT}/${id}`
  )) as ResponseProps;

  if (response && response.success) {
    return { success: true, blobFile: response.data };
  }

  return { success: false };
};

export const deleteDocument = async (id) => {
  const deleteFileUrl = `${getSessionStorageItem(
    CONSTANTS.AWGMENT_API
  )}${EULA_TEXT_DOWNLOAD_ENDPOINT}`;
  const r: ResponseProps = (await request.delete(
    deleteFileUrl
  )) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false };
};

export const fetchFileText = async (id: string) => {
  const Token = getSessionStorageItem(CONSTANTS.REACT_TOKEN);

  const fetchEulaTextUrl = `${getSessionStorageItem(
    CONSTANTS.AWGMENT_API
  )}${EULA_TEXT_DOWNLOAD_ENDPOINT}${getSessionStorageItem(
    CONSTANTS.EULA_DOC_ID
  )}`;

  try {
    const response = await fetch(fetchEulaTextUrl, {
      method: REQUEST_METHODS.GET,
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    });
    const blobData = await response.blob();
    return blobData.text();
  } catch (error) {
    throw new Error(CONSTANTS.FAILED_TO_FETCH_EULA_MSG);
  }
};

export const uploadProfileImage = async (
  file: string,
  name: string
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${name}`);
  const params = {
    file: fileOfBlob,
  };
  const r: ResponseProps = (await request.postForm(
    `${getSessionStorageItem(
      CONSTANTS.AWGMENT_API
    )}${USER_PREFERENCE}${PROFILE_PICTURE_ENDPOINT}`,
    params
  )) as ResponseProps;
  if (r) {
    return {
      success: r.success ? true : false,
      message: r.message,
      data: r.data,
    };
  }
  return { success: false };
};
