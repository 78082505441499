import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomSearchField from '../../../components/common/customSearch/CustomSearchField';
import CustomButton from '../../../components/common/customButton';
import { makeStyles } from '@material-ui/core';
import { PlaceholderComponent } from '../../../screen/dashboard/PlaceHolderComponent';
import CONSTANTS from '../../../constants/constants';

const useStyles = makeStyles((theme) => ({
  tableBodyCell: {
    padding: '0rem 1rem !important',
  },
  tableTitle: {
    color: '#0046FF',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  noDataFoundBox: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disablePadding',
})<{ disablePadding?: boolean }>(({ theme, disablePadding }) => ({
  padding: disablePadding ? '0' : '1rem 1.5rem !important',
  overflow: 'hidden',
  height: 'calc(100vh - 3.8rem)',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    padding: disablePadding ? '0' : '1rem 1.2rem 1rem 4rem !important',
  },
  [theme.breakpoints.up('sm')]: {
    padding: disablePadding ? '0' : '1rem 1.4rem 1rem 2.6rem !important',
  },
  [theme.breakpoints.up(900)]: {
    padding: disablePadding ? '0' : '1rem 1.8rem 1rem 2.5rem !important',
  },
  [theme.breakpoints.up('lg')]: {
    padding: disablePadding ? '0' : '1rem 2rem !important',
  },
}));


const TableWrapper = styled(TableContainer)(({ theme }) => ({
  width: '100%',
  overflow: 'invisible',
  marginTop: '-2rem',
  maxHeight: '80%',
  backgroundColor: '#FFFFFF',
  [theme.breakpoints.up(2560)]: {
    marginTop: '-2.6rem',
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 690,
}));

const HeaderCell = styled(TableCell)<{ bgColor?: string; textColor?: string }>(
  ({ theme, bgColor, textColor }) => ({
    fontWeight: 600,
    backgroundColor: bgColor || '#0046FF',
    color: textColor || '#ffffff', 
    fontSize: '0.9rem',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem !important',
    },
    [theme.breakpoints.up(1920)]: {
      fontSize: '1.3rem !important',
    },
    [theme.breakpoints.up(2560)]: {
      fontSize: '1.6rem !important',
      padding: '1.2rem 1rem !important',
    },
    [theme.breakpoints.up(3840)]: {
      fontSize: '2rem !important',
      padding: '1.5rem 1.5rem !important',
    },
  })
);


const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const SearchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const PaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  backgroundColor: '#FFFFFF',
}));

const EllipsisCell = styled(TableCell)(({ theme }) => ({
  maxWidth: 150,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '0.9rem',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.2rem !important',
  },
  [theme.breakpoints.up(1920)]: {
    fontSize: '1.3rem !important',
  },
  [theme.breakpoints.up(2560)]: {
    fontSize: '1.6rem !important',
  },
  [theme.breakpoints.up(3840)]: {
    fontSize: '2rem !important',
  },
}));

interface ReusableTableProps {
  id: string;
  title?: string;
  columns: any[];
  data: any[];
  totalCount?: number;
  page?: number;
  rowsPerPage?: number;
  onPageChange?: any;
  onRowsPerPageChange?: any;
  onSearch?: any;
  searchPlaceholder?: string;
  onAddItem?: any;
  buttonLabel?: string;
  renderExpandedRow?: (row: any) => React.ReactNode;
  renderCustomCell?: any;
  isLoading?: boolean;
  renderUploadButton?: () => React.ReactNode;
  showPagination?: boolean;
  showTitle?: boolean;
  showSearch?: boolean;
  showAddButton?: boolean;
  headerBgColor?: string;  
  headerTextColor?: string;
  disablePadding?: boolean;

}

const ReusableTable: React.FC<ReusableTableProps> = ({
  id,
  title,
  columns,
  data,
  totalCount = 0,
  page = 0,
  rowsPerPage = 10,
  onPageChange,
  onRowsPerPageChange,
  onSearch,
  searchPlaceholder,
  onAddItem,
  buttonLabel,
  renderExpandedRow,
  renderCustomCell,
  isLoading = false,
  renderUploadButton,
  showPagination = true,
  showTitle = true,
  showSearch = true,
  showAddButton = true,
  headerBgColor,
  headerTextColor,
  disablePadding = false,
  
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);


  const handleSearch = (event) => {
    const newSearchTerm = event?.target?.value;
    setSearchTerm(newSearchTerm);
    onSearch && onSearch(newSearchTerm);
  };

  const handleExpandRow = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const renderCellContent = (column, row) => {
    const content = renderCustomCell
      ? renderCustomCell(column, row, handleExpandRow)
      : column?.format
      ? column?.format(row[column?.id])
      : row[column?.id];

    return (
      <EllipsisCell
        key={column?.id}
        align={column?.align || 'left'}
        title={typeof content === 'string' ? content : ''}
      >
        {content}
      </EllipsisCell>
    );
  };

  const classes = useStyles();

  const renderTableBody = () => {
    if (data?.length === 0) {
      return <Box className={classes.noDataFoundBox}>No Data Found</Box>;
    }
    return (
      <TableBody>
        {data?.map((row, index) => {
          return(
          <React.Fragment key={`${title}-${index}`}>
            <TableRow hover>
              {columns?.map((column) => renderCellContent(column, row))}
            </TableRow>
            {renderExpandedRow && (
              <TableRow>
                <TableCell className={classes.tableBodyCell} colSpan={12}>
                  {renderExpandedRow(row)}
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        )})}
      </TableBody>
    );
  };

  const renderReusableTable = () => {
    return (
      <>
        <TableWrapper>
          <StyledTable stickyHeader>
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <HeaderCell
                    key={column?.id}
                    align={column?.align || 'left'}
                    style={{ minWidth: column?.minWidth }}
                    bgColor={headerBgColor}
                    textColor={headerTextColor}
                  >
                    {column?.label}
                  </HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={12}>
                  <Box className={classes.loaderContainer}>
                    <div className="refresh-data-loader"></div>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              renderTableBody()
            )}
          </StyledTable>
        </TableWrapper>
        {showPagination && (
          <PaginationContainer>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={onPageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </PaginationContainer>
        )}
      </>
    );
  };

  return (
    <Root disablePadding={disablePadding} id={id} data-testid={id}>
      {showTitle && title && (
        <TitleBox>
          <Typography
            fontWeight={600}
            variant="h2"
            className={classes.tableTitle}
          >
            {title}
          </Typography>
        </TitleBox>
      )}

      {(showSearch || showAddButton || renderUploadButton) && (
        <SearchBox>
          {renderUploadButton && renderUploadButton()}
          {showSearch && (
            <CustomSearchField
              id={`${title ? title.toLowerCase() : 'table'}SearchField`}
              value={searchTerm}
              onChange={handleSearch}
              placeholder={searchPlaceholder || CONSTANTS.DEFAULT_SEARCH_PLACEHOLDER}
              fullWidth={false}
            />
          )}
          {showAddButton && onAddItem && buttonLabel && (
            <Box ml="auto">
              <CustomButton
                id={`add${title ? title : 'Item'}Btn`}
                label={buttonLabel}
                onClick={onAddItem}
                fontWeight={600}
              />
            </Box>
          )}
        </SearchBox>
      )}
      <PlaceholderComponent
        text={''}
        padding={0}
        height={{ xs: 330, sm: 340, md: 340, lg: 420, xl: 850 }}
        loading={isLoading}
        isFetchingData={true}
        id={`${id}-${title ? title : 'table'}`}
      >
        {renderReusableTable()}
      </PlaceholderComponent>
    </Root>
  );
};

export default ReusableTable;
